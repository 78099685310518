import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, of, BehaviorSubject, Subject } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { API_ENDPOINT } from "../app.settings";
import { PassportService } from "./passport.service";

@Injectable({
  providedIn: "root"
})
export class CardService {
  private _defaultCardSubject: BehaviorSubject<object>;
  private _defaultCard: Observable<object>;

  constructor(
    private _httpClient: HttpClient,
    private _passportService: PassportService
  ) {
    this._defaultCardSubject = new BehaviorSubject<object>({});
    this._defaultCard = this._defaultCardSubject.asObservable();
  }

  initDefaultCard(): void {
    this._httpClient
      .get(
        API_ENDPOINT + "/users/current/cards/stripe/default",
        this._passportService.httpOptions
      )
      .subscribe(
        card => {
          this._defaultCardSubject.next(card);
        },
        error => {
          this._defaultCardSubject.next(Object.create(null));
        }
      );
  }

  storeExternalAccount(token: string) {
    return this._httpClient.post(
      API_ENDPOINT + "/users/current/cards/stripe/external/store",
      { token },
      this._passportService.httpOptions
    );
  }

  addCard(token: string) {
    return this._httpClient
      .post(
        API_ENDPOINT + "/users/current/cards/stripe/store",
        { token },
        this._passportService.httpOptions
      )
      .pipe(
        map((card: object) => {
          this._defaultCardSubject.next(card);
          return card;
        }),
        catchError(error => {
          this._defaultCardSubject.next(Object.create(null));
          return of(Object.create(null));
        })
      );
  }

  get defaultCard(): Observable<object> {
    return this._defaultCard;
  }
}
