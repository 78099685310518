import { Component, OnInit } from "@angular/core";
import { AuthService } from "../../../services/auth.service";
import { IUser } from "../../../models/user/user.model";
import { select, Store } from "@ngrx/store";
import { UserState } from "src/app/store/reducers/user";
import { CLIENT_PREMIUM_CONTENT } from '../../../app.settings';

@Component({
  selector: "app-dashboard-side-bar",
  templateUrl: "./dashboard-side-bar.component.html",
  styleUrls: ["./dashboard-side-bar.component.css"]
})
export class ClientDashboardSideBarComponent implements OnInit {
  public currentUser: IUser;
  constructor(
    private _authService: AuthService,
    private _userStore: Store<UserState>
  ) { }

  ngOnInit() {
    this._userStore.pipe(select("user")).subscribe((data: UserState) => {
      this.currentUser = data.activeUser;
    });
  }

  logout(): void {
    this._authService.logout();
  }

  aditionalSiteBarItems(): Array<{ iconFile: string, link: string, label: string }> {
    return CLIENT_PREMIUM_CONTENT;
  }
}
