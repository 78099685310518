import * as uiAction from '../actions/ui.action';

export interface UiState {
  subTitle: string;
}

const init: UiState = {
  subTitle: '',
};

export function uiReducer(state: UiState = init, action: uiAction.Action) {
  switch (action.type) {
    case uiAction.SET:
      return {
        ...state,
        ...action.payload,
      };
    case uiAction.UNSET:
      return init;
    default:
      return state;
  }
}
