import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { isEmpty } from '../../../../helpers/object';

@Component({
  selector: 'app-tree-list',
  templateUrl: './tree-list.component.html',
  styleUrls: ['./tree-list.component.css']
})
export class TreeListComponent implements OnInit {
  @Input('nodes') private _nodes:Array<object>;
  @Input('level') level:number = 0;
  @Input('bindValue') bindValue:string = 'id';
  @Input('bindTitle') bindTitle:string = 'name';
  @Input('bindChild') bindChild:string = 'child';
  @Input() filter:string = '';
  @Output('selected') selected:EventEmitter<any> = new EventEmitter<any>();
  constructor() { }

  ngOnInit() {
  }

  get nodes(){
    if(!!this.filter){
      return this.filterFn([...this._nodes], this.filter);
    }
    return this._nodes;
  }

  private filterFn(nodes:Array<object>, filter:string){
    return nodes.filter((item:object)=>{
      const has = !!item[this.bindTitle].toLowerCase().match(filter.toLowerCase());
      if(!isEmpty(item[this.bindChild]) && !has){
        return !isEmpty(this.filterFn(item[this.bindChild], filter)); //&& (item[this.bindChild] = this.filterFn(item[this.bindChild], filter));
      }
      return has;
    });
  }

  selectedHandler(node:object){
    this.selected.emit(node);
  }

}
