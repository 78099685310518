import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ChatService } from '../../../services/chat.service';
import { QuestionService } from '../../../services/question.service';
import { IChat } from '../../../models/chat/chat.model';
import {
  IQuestion,
  QuestionViewOption,
} from '../../../models/question/question.model';

import { Store, select } from '@ngrx/store';
import { ChatState } from 'src/app/store/reducers/chat.reducer';
import {
  SetChatState,
  UnsetChatState,
} from '../../../store/actions/chat.action';
import { Subscription } from 'rxjs';
import { SetUiState, UnsetUiState } from 'src/app/store/actions/ui.action';
import { UiState } from 'src/app/store/reducers/ui.reducer';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.css'],
})
export class ClientChatComponent implements OnDestroy {
  private _chatId: number;
  private _questionId: number;
  public question: IQuestion;
  public chat: IChat;
  public viewOption: QuestionViewOption;
  public chatObservable: Subscription;

  constructor(
    private _route: ActivatedRoute,
    private _chatService: ChatService,
    private _questionService: QuestionService,
    private _store: Store<ChatState>,
    private _uiStore: Store<UiState>
  ) {
    this.chatObservable = _store
      .pipe(select('chat'))
      .subscribe((data: ChatState) => {
        this.question = data.question;
        this.viewOption = data.viewOption;
        this.chat = data.chat;
      });

    _uiStore.dispatch(new SetUiState({ subTitle: 'Question' }));

    this._route.params.subscribe((params) => {
      this._questionId = params['questionID'] as number;
      this._chatId = params['chatID'] as number;
      this.getQuestion(this._questionId);
      this.getChat(this._chatId);
    });
  }

  private getQuestion(id) {
    this._questionService
      .getQuestion(id)
      .subscribe((data: { question: IQuestion; meta: QuestionViewOption }) => {
        this._store.dispatch(
          new SetChatState({
            question: data.question,
            viewOption: data.meta,
          })
        );
      });
  }

  private getChat(id) {
    if (id) {
      this._chatService.getChat(id).subscribe((chat: IChat) => {
        this._store.dispatch(
          new SetChatState({
            chat: chat,
          })
        );
      });
    }
  }

  updateQuestionState() {
    this.getQuestion(this._questionId);
    this.getChat(this._chatId);
  }

  ngOnDestroy() {
    this._store.dispatch(new UnsetChatState());
    this._uiStore.dispatch(new UnsetUiState());
    this.chatObservable.unsubscribe();
  }
}
