import { Component, forwardRef, Input, ViewChild, ElementRef, NgZone } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { map } from 'rxjs/operators';

import { CategoryService } from "../../../services/category.service";
import { isArray } from 'util';

@Component({
  selector: 'app-category-select',
  template: `
    <ng-select
      placeholder="{{placeholder}}"
      [clearable]="'false'"
      dropdownPosition="bottom"
      [(ngModel)]="selectValue"
      [closeOnSelect]="'true'"
      [items]="categories"
      bindLabel="name"
      [disabled]="disabled"
      bindValue="id"
      [multiple]="multiple"
    >
    <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
        <div *ngFor="let item of items; let last = last">
            <span>{{item.name}}</span>
            <span *ngIf="!last">,</span>
        </div>
    </ng-template>
   </ng-select>
  `,
  styleUrls: ['./category-select.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CategorySelectComponent),
      multi: true,
    }
  ],
})

export class CategorySelectComponent implements  ControlValueAccessor{
  @Input('placeholder') placeholder:string = 'Category';
  @Input() multiple:boolean = false;
  @Input() disabled:boolean = false;
  private _selectValue;
  private _onTouchedCallback: () => {};
  private _onChangeCallback: (_:any) => {};
  public categories:Array<any>;
  f=0;
  constructor(
    private zone:NgZone,
    private _categoryService:CategoryService
  ) {
    this._categoryService.categoriesObservable({id:'id', title:'name', recursive_tree:'child'})
    .subscribe(
      categories => {
        return this.categories = categories;
      }
    );
  }

  g():number{
    return this.f++;
  }

  get selectValue(): any {
    return this._selectValue;
  }
  set selectValue(value: any) {
    this.writeValue(value);
    this._onChangeCallback && this._onChangeCallback(value);
    this._onTouchedCallback && this._onTouchedCallback();
  }

  writeValue(value: any) {
    this._selectValue = value;
  }

  registerOnChange(fn: any) {
    this._onChangeCallback = fn || (() => {});
  }

  registerOnTouched(fn: any) {
    this._onTouchedCallback = fn || (() => {});
  }

}
