import { Injectable } from '@angular/core';
import { PassportService } from './passport.service';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { API_ENDPOINT } from '../app.settings';
import { FileService } from './file.service';
import {
  IQuestion,
  QuestionViewOption,
} from '../models//question/question.model';

@Injectable({
  providedIn: 'root',
})
export class QuestionService {
  static STATUS_COMPLETED = 'completed';
  static STATUS_NEW = 'new';
  static STATUS_DRAFT = 'draft';
  static STATUS_REVIEW_COMPLETED = 'review_completed';
  static STATUS_ANSWER_REJECTED = 'answer_rejected';
  static STATUS_AGENT_ASSIGNED = 'agent_assigned';
  static STATUS_AGENT_REPLIED = 'agent_replied';
  static STATUS_CLIENT_REPLIED = 'client_replied';
  static STATUS_AWAITING_APPROVAL = 'awaiting_approval';
  static STATUS_CANCELLED = 'cancelled';
  static STATUS_UNDERPAID = 'underpaid';
  static STATUS_REMOVED_BY_ADMIN = 'removed_by_admin';

  constructor(
    private _passportService: PassportService,
    private _httpService: HttpClient,
    private _fileService: FileService
  ) {}

  public store(data): Observable<any> {
    return this._httpService.post(
      API_ENDPOINT + '/questions',
      this._fileService.toFormData(data),
      this._passportService.httpOptions
    );
  }

  public clientQuestionsStory(options: HttpParams) {
    return this._httpService
      .get(API_ENDPOINT + '/client/questions', {
        ...this._passportService.httpOptions,
        params: options,
      })
      .pipe(
        map(
          (data) => {
            return {
              data: data['data'],
              ...data['links'],
              ...data['meta'],
            };
          }
        )
      );
  }

  getQuestion(
    questionID: number,
    params?: HttpParams
  ): Observable<{ question: IQuestion; meta: QuestionViewOption }> {
    return this._httpService
      .get(`${API_ENDPOINT}/questions/${questionID}`, {
        ...this._passportService.httpOptions,
        params: params,
      })
      .pipe(
        map((response) => {
          return {
            question: response['data'],
            meta: response['meta'],
          };
        })
      );
  }

  questions(options: HttpParams) {
    return this._httpService.get(`${API_ENDPOINT}/questions`, {
      ...this._passportService.httpOptions,
      params: options,
    });
  }

  publish(questionID: number, params: HttpParams) {
    return this._httpService.post(
      `${API_ENDPOINT}/questions/${questionID}/publish`,
      params,
      this._passportService.httpOptions
    );
  }

  update(questionID: number, params: HttpParams) {
    return this._httpService.put(
      `${API_ENDPOINT}/questions/${questionID}`,
      params,
      this._passportService.httpOptions
    );
  }

  addExtraCredit(questionID: number, params: HttpParams) {
    return this._httpService
      .post(
        `${API_ENDPOINT}/questions/${questionID}/extra-credits`,
        params,
        this._passportService.httpOptions
      )
      .pipe(
        map((response) => {
          return {
            question: response['data'],
            meta: response['meta'],
          };
        })
      );
  }
}
