import { Component, OnInit, Input, ContentChild, Output, EventEmitter } from '@angular/core';


@Component({
  selector: 'app-profile-field',
  templateUrl: './profile-field.component.html',
  styleUrls: ['./profile-field.component.css']
})
export class ProfileFieldComponent implements OnInit {
  @Input('value') initValue = '';
  @Input('key') key:string;
  @Output('updated') updatedEvent: EventEmitter<object> = new EventEmitter<object>();
  private _value:string="";
  private _isShowEditField:boolean = false;
  constructor() { }

  ngOnInit() {

  }

  setEditFieidState(show:boolean):void{
    this._isShowEditField = show;
  }

  change(value){
    this._value = value;
  }

  update(){
    if(this._value && this.key){
      this.updatedEvent.emit({
        [this.key]:this._value
      });
    }
    this.setEditFieidState(false);
  }

  hasInit():boolean{
    return !!this.initValue;
  }

  isShowEditField():boolean{
    return this._isShowEditField;
  }

  isShowField():boolean{
    return !this._isShowEditField;
  }

}
