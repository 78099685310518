import {
  Component,
  forwardRef,
  Input,
  ViewChild,
  ElementRef,
  NgZone,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { map } from 'rxjs/operators';

import { CountryRegionService } from '../../../services/country-region.service';
import { inArray } from 'src/app/helpers/array';

interface countrySelectItem {
  title: string;
  value: string;
}

@Component({
  selector: 'app-country-select',
  template: `
    <ng-select
      placeholder="{{ placeholder }}"
      [clearable]="'false'"
      dropdownPosition="bottom"
      [(ngModel)]="selectValue"
      [closeOnSelect]="'true'"
      [disabled]="isDisabled()"
    >
      <ng-option *ngFor="let country of countries" [value]="country.value">{{
        country.title
      }}</ng-option>
    </ng-select>
  `,
  styleUrls: ['./country-select.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CountrySelectComponent),
      multi: true,
    },
  ],
})
export class CountrySelectComponent implements ControlValueAccessor {
  @ViewChild('select') select: ElementRef;
  @Input('placeholder') placeholder: string = 'Country';
  @Input('bindValue') bindValue: string = 'code';
  @Input() only: Array<object>;
  @Input() disabled: boolean = false;
  @Input('bindTitle') bindTitle: string = 'name';

  public countries: countrySelectItem[];
  private _selectValue;
  private _onTouchedCallback: () => {};
  private _onChangeCallback: (_: any) => {};

  constructor(
    private zone: NgZone,
    private _countryRegionService: CountryRegionService
  ) {
    this._countryRegionService
      .countryObservable()
      .pipe(
        map((countries: any[]) => {
          return countries
            .filter((country) => {
              if (!this.only) {
                return true;
              }
              if (inArray(this.only, country['code'])) {
                return true;
              }
              return false;
            })
            .map((country) => {
              return {
                title: country[this.bindTitle],
                value: country[this.bindValue],
              };
            });
        })
      )
      .subscribe((countries) => {
        this.zone.run(() => {
          this.countries = countries;
        });
      });
  }

  isDisabled(): boolean {
    return this.disabled;
  }

  get selectValue(): any {
    return this._selectValue;
  }
  set selectValue(value: any) {
    this.writeValue(value);
    this._onChangeCallback(value);
    this._onTouchedCallback();
  }

  //From ControlValueAccessor interface
  writeValue(value: any) {
    this._selectValue = value;
  }

  //From ControlValueAccessor interface
  registerOnChange(fn: any) {
    this._onChangeCallback = fn;
  }

  //From ControlValueAccessor interface
  registerOnTouched(fn: any) {
    this._onTouchedCallback = fn;
  }
}
