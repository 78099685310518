import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class AppService {
  public API_ENDPOINT: string = '';
  constructor(private _toastrService: ToastrService) {}

  public setFormGroupErrors(formGroup: FormGroup, errors: Array<string>) {
    for (let i in errors) {
      formGroup.controls[i] &&
        formGroup.controls[i].setErrors({ incorrect: true });
    }
  }

  public getError({ error }): Array<string> {
    let errors;
    if (error.errors && (errors = error.errors)) {
      for (let index in errors) {
        errors[index] = errors[index].join('');
      }
      return errors;
    } else {
      const f: Array<string> = [];
      f['global'] = error.message;
      return f;
    }
  }

  public showGlobalError(errors): void {
    const err = errors['global'];
    if (err) {
      this._toastrService.error(err);
    }
  }
}
