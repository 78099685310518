import {
  Component,
  OnInit,
  ContentChild,
  ElementRef,
  AfterViewInit,
} from '@angular/core';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.css'],
})
export class TooltipComponent implements OnInit, AfterViewInit {
  private _activeAgentModal: NgbActiveModal;
  constructor(private _modalService: NgbModal) {}
  @ContentChild('modalComponent') modalComponent: ElementRef;
  ngOnInit() {}

  openModal(content) {
    this._activeAgentModal = this._modalService.open(content, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'lg',
    });
  }

  ngAfterViewInit() {
    if (this.modalComponent['closed']) {
      this.modalComponent['closed'].subscribe(() => {
        this._activeAgentModal.close();
      });
    }
  }
}
