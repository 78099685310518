import { Component, OnInit } from "@angular/core";
import { CurrentUser, IUser } from "src/app/models/user/user.model";
import { AuthService } from "src/app/services/auth.service";
import { AgentWalletTransactionComponent } from "./transaction/transaction.component";
import { CreditService } from "src/app/services/credit.service";
import { OnDestroy } from "@angular/core";
import { Store, select } from "@ngrx/store";
import { UiState } from "../../../store/reducers/ui.reducer";
import { SetUiState, UnsetUiState } from "../../../store/actions/ui.action";
import { UserState } from "src/app/store/reducers/user";
import { SettingService } from "src/app/services/setting.service";
import {
  FormControl,
  FormBuilder,
  FormGroup,
  Validators
} from "@angular/forms";
import { AgentService } from "src/app/services/agent.service";
import { Http } from "@angular/http";
import { HttpParams } from "@angular/common/http";

@Component({
  selector: "app-client-wallet",
  templateUrl: "./wallet.component.html",
  styleUrls: ["./wallet.component.css"],
  entryComponents: [AgentWalletTransactionComponent]
})
export class AgentWalletComponent implements OnInit, OnDestroy {
  public currentUser: IUser;
  public minWithdrawalAmount: number;
  public withdrawalForm: FormGroup;

  constructor(
    private _authService: AuthService,
    private _agentService: AgentService,
    private _creditService: CreditService,
    private _uiStore: Store<UiState>,
    private _userStore: Store<UserState>,
    private _settingService: SettingService,
    private _fb: FormBuilder
  ) {
    _uiStore.dispatch(new SetUiState({ subTitle: "Wallet" }));
    this._settingService
      .settingObservable("withdrawal-min")
      .subscribe((data: number) => {
        this.minWithdrawalAmount = data;
      });

    this._userStore.pipe(select("user")).subscribe((data: UserState) => {
      this.currentUser = data.activeUser;
    });

    this.withdrawalForm = this.initWithdrawalForm(_fb);
  }

  ngOnInit() {}

  withdrawalHandler(fields) {
    const params = new HttpParams({ fromObject: fields });
    this._agentService.withdrawalMoneyToPaypal(params).subscribe(data => {});
  }

  paginateComponentRef() {
    return AgentWalletTransactionComponent;
  }

  ngOnDestroy() {
    this._uiStore.dispatch(new UnsetUiState());
  }

  getCredits() {
    return this._creditService.creditsStory.bind(this._creditService);
  }

  initWithdrawalForm(fb: FormBuilder): FormGroup {
    return fb.group({
      email: [null, [Validators.email, Validators.required]]
    });
  }

  disabledWithdrawalSubmit(
    isValidForm: boolean,
    canWithdrawal:boolean
  ): boolean {
  return !(isValidForm && canWithdrawal);
  }
}
