import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { isEmpty } from 'src/app/helpers/object';

@Component({
  selector: 'app-profile-category-select-field',
  templateUrl: './category-select.component.html',
  styleUrls: ['./category-select.component.css'],
})
export class ProfileCategorySelectComponent implements OnInit {
  @Input('value') initValue: Array<number>;
  @Input('key') key: string;
  @Output('updated') updatedEvent: EventEmitter<object> = new EventEmitter<
    object
  >();
  public value: Array<number>;
  public textValue: string = '';
  private _isShowEditField: boolean = false;
  private _treeValues: Array<object>;

  constructor() {}

  ngOnInit() {
    this.value = this.initValue;
  }

  setEditFieidState(show: boolean): void {
    this._isShowEditField = show;
  }

  change(value) {
    this.value = value;
    this.update();
  }

  get treeValues() {
    return this._treeValues;
  }

  cancel() {
    this.value = this.initValue;
  }

  update() {
    if (this.value && this.key) {
      this.updatedEvent.emit({
        [this.key]: this.value,
      });
      this.setEditFieidState(false);
    }
  }

  hasInit(): boolean {
    return !isEmpty(this.initValue);
  }

  isShowEditField(): boolean {
    return this._isShowEditField;
  }

  isShowField(): boolean {
    return !this._isShowEditField;
  }
}
