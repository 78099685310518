import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import {
  FormGroup,
  FormBuilder,
  AbstractControl,
  Validators,
} from '@angular/forms';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css'],
})
export class ResetPasswordComponent implements OnInit {
  public globalResponseError: string;
  public setPasswordForm: FormGroup;
  private token: string;

  constructor(
    private _authService: AuthService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _fb: FormBuilder,
    private _toastrService: ToastrService
  ) {
    this.setPasswordForm = this.initSetPasswordForm();
  }

  ngOnInit() {
    this._route.queryParams.subscribe((params) => {
      this.token = params['token'];
    });
  }

  setPassword(passwordData) {
    const {
      passwords: { password, password_confirmation },
      ...next
    } = passwordData;
    const request = {
      token: this.token,
      password,
      password_confirmation,
      ...next,
    };
    this._authService.setPassword(request).subscribe(
      (response) => {
        const message = response.message || 'Password changed';
        this._toastrService.success(message);
        this._router.navigate(['/client/login']);
      },
      (errorResponse) => {
        console.log(errorResponse);
      }
    );
  }

  passwordConfirming(c: AbstractControl): { invalid: boolean } {
    if (c.get('password').value !== c.get('password_confirmation').value) {
      c.get('password_confirmation').markAsTouched({ onlySelf: true });
      c.get('password').markAsTouched({ onlySelf: true });
      return { invalid: true };
    }
  }

  initSetPasswordForm(): FormGroup {
    return this._fb.group({
      email: [
        null,
        Validators.compose([Validators.required, Validators.email]),
      ],
      passwords: this._fb.group(
        {
          password: [null, Validators.required],
          password_confirmation: [null, Validators.required],
        },
        {
          validator: Validators.compose([this.passwordConfirming]),
        }
      ),
    });
  }
}
