import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { isEmpty } from '../../../helpers/object';
import { AuthService } from '../../../services/auth.service';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { IUser } from '../../../models/user/user.model';
import { CardService } from '../../../services/card.service';
import { UserService } from 'src/app/services/user.service';
import { select, Store } from '@ngrx/store';
import { UserState } from 'src/app/store/reducers/user';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css'],
})
export class ClientProfileComponent implements OnInit {
  public stripeCheck: Subject<object> = new Subject<object>();
  public currentUser: IUser;
  public is = isEmpty;
  private _activeStripeModal: NgbActiveModal;
  public defaultCart: object;

  constructor(
    private _authService: AuthService,
    private _modalService: NgbModal,
    private _userService: UserService,
    private _cardService: CardService,
    private _userStore: Store<UserState>
  ) {}

  ngOnInit() {
    this._userStore.pipe(select('user')).subscribe((data: UserState) => {
      this.currentUser = data.activeUser;
    });
    this._cardService.initDefaultCard();
    this._cardService.defaultCard.subscribe((card: object) => {
      this.defaultCart = card;
    });
  }

  openStripeModal(content) {
    this._activeStripeModal = this._modalService.open(content, {
      ariaLabelledBy: 'modal-basic-title',
    });
  }

  async addedStripeCard() {
    await this._authService.currentUserObservable();
    this._cardService.initDefaultCard();
    this._activeStripeModal.close();
  }

  updateField(event) {
    this._userService.update(event).subscribe((data) => {
      this._authService.currentUserObservable();
    });
  }

  avatarFileUpload(file: File): void {
    const filesForm = new FormData();
    filesForm.append('avatar', file);
    this.updateField(filesForm);
  }
}
