import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { IUser } from '../../../../models/user/user.model';
import { isEmpty } from 'src/app/helpers/object';
import { select, Store } from '@ngrx/store';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserState } from 'src/app/store/reducers/user';
import { CardService } from '../../../../services/card.service';
import { AuthService } from 'src/app/services/auth.service';
import { CreditService } from 'src/app/services/credit.service';

@Component({
  selector: 'app-client-add-credit-popup',
  templateUrl: './add-credit.component.html',
  styleUrls: ['./add-credit.component.css'],
})
export class AddCreditComponent implements OnInit {
  public currentUser: IUser;
  private _modal;
  public defaultCart: object;
  private _creditAmount: number = 30;
  public activePayment: string = 'paypal';
  public transactionSubject: Subject<void> = new Subject<void>();
  public stripeCheck: Subject<object> = new Subject<object>();
  @Output('closed') closed: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    private _userStore: Store<UserState>,
    public cardService: CardService,
    public creditService: CreditService,
    private _authService: AuthService,
    private _modalService: NgbModal
  ) {}

  ngOnInit() {
    Promise.resolve(null).then((_) => {
      this._userStore.pipe(select('user')).subscribe((data: UserState) => {
        this.currentUser = data.activeUser;
      });
      this.cardService.initDefaultCard();

      this.cardService.defaultCard.subscribe((card: object) => {
        this.defaultCart = card;
      });
    });
  }
  setActivePayment(val: string) {
    this.activePayment = val;
  }

  isActivePayment(type: string) {
    return this.activePayment === type;
  }

  stripeTokenHandles(token: string) {
    this.cardService.addCard(token).subscribe((card: object) => {
      this._addCredit({ amount: this._creditAmount });
    });
  }

  private _addCredit(options: object) {
    this.creditService.addCreditByDefaultCard(options).subscribe(() => {
      this.paymentExecutedHandler();
    });
  }

  paymentExecutedHandler() {
    this.transactionSubject.next();
    this._authService.currentUserObservable();
    this.cardService.initDefaultCard();
    this.closed.next();
  }

  addCredit(defaultCart: object) {
    if (isEmpty(this.defaultCart)) {
      this.emitEventStripeCheck();
    } else {
      this._addCredit({ amount: this._creditAmount });
    }
  }

  emitEventStripeCheck(options: object = {}) {
    this.stripeCheck.next(options);
  }

  async addedStripeCard() {
    await this._authService.currentUserObservable();
    this.cardService.initDefaultCard();
    this._modal.close();
  }

  open(content) {
    this._modal = this._modalService.open(content, {
      ariaLabelledBy: 'modal-basic-title',
    });
  }

  setCredit(amount: number): void {
    this._creditAmount = amount;
  }

  set creditAmount(amount: number) {
    this._creditAmount = amount;
  }

  get creditAmount(): number {
    return this._creditAmount;
  }

  closePopup(): void {
    this.closed.next();
  }
}
