import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { SettingService } from '../services/setting.service';


@Injectable({
  providedIn: 'root'
})
export class BetaModeGuard implements CanActivate {
  constructor (
    private _router:Router,
    private _settingService:SettingService
  ){

  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this._settingService.settingObservable('beta-mode')
    .pipe(
      map((mode)=>{
        if(+mode){
          this._router.navigate(['/beta-mode']);
        }
        return !(+mode);
      }),
      catchError((error)=>{
        this._router.navigate(['/beta-mode']);
        return of(false);
      })
    );
  }
}
