import { Action } from '@ngrx/store';
import * as chatAction from '../actions/chat.action';
import {
  IQuestion,
  QuestionViewOption,
} from '../../models/question/question.model';
import { IChat } from 'src/app/models/chat/chat.model';

export interface ChatState {
  question?: IQuestion;
  chat?: IChat;
  viewOption?: QuestionViewOption;
}

const init: ChatState = {
  question: null,
  chat: null,
  viewOption: null,
};

export function chatReducer(
  state: ChatState = init,
  action: chatAction.Action
) {
  switch (action.type) {
    case chatAction.SET:
      return {
        ...state,
        ...action.payload,
      };
    case chatAction.UNSET:
      return init;
    default:
      return state;
  }
}
