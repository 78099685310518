import { Component, OnInit, Input, Inject } from '@angular/core';

interface Transaction{
  amount:number,
  created_at:string,
  balance_snapshot:number
}

@Component({
  selector: 'app-profile-transaction',
  templateUrl: './profile-transaction.component.html',
  styleUrls: ['./profile-transaction.component.css']
})


export class ProfileTransactionComponent implements OnInit {
  @Input('data') transaction:Transaction;
  constructor( @Inject('paginateItemData') private data ) {
    this.transaction = data;
  }

  ngOnInit() {
  }

}
