import { Component, OnInit, Input } from '@angular/core';
import { IQuestion, QuestionViewOption } from 'src/app/models/question/question.model';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { RejectMessageService } from 'src/app/services/reject-message.service';
import { SetChatState } from 'src/app/store/actions/chat.action';
import { Store } from '@ngrx/store';
import { ChatState } from 'src/app/store/reducers/chat.reducer';

@Component({
  selector: 'app-agent-reject-message',
  templateUrl: './reject-message.component.html',
  styleUrls: ['./reject-message.component.css']
})
export class AgentRejectMessageComponent implements OnInit {
  @Input('question') question:IQuestion;
  public rejectForm: FormGroup;

  constructor(
    private _fb: FormBuilder,
    private _rejectMessageService:RejectMessageService,
    private _store:Store<ChatState>,
  ) {
  }

  ngOnInit() {
    this.initRejectForm();
  }

  addRejectMessage(values){
    this._rejectMessageService.store(values).subscribe(
      (data:{question:IQuestion, meta:QuestionViewOption})=>{
        this._store.dispatch(new SetChatState({
          question:data.question,
          viewOption:data.meta
        }));
      }
    )
  }

  initRejectForm():void{
    this.rejectForm = this._fb.group({
      message:['', Validators.required],
      question_id:[this.question.id, Validators.required],
    });
  }

  isShowRejectForm(){
    return this.question ? ( !this.question.agentRejectMessage ) : false;
  }

  isShowRejectedArea(){
    return this.question ? ( this.question.agentRejectMessage ) : false;
  }

}
