import { Injectable } from '@angular/core';
import { API_ENDPOINT } from "../app.settings";
import { PassportService } from "./passport.service";
import { map, catchError } from 'rxjs/operators';
import { HttpClient, HttpParams } from '@angular/common/http';
import { FileService } from "./file.service";

@Injectable({
  providedIn: 'root'
})
export class MessageService {

  constructor(
    private _passportService:PassportService,
    private _httpClient:HttpClient,
    private FileService:FileService
  ) { }

  sendMessage(chatID:number, messageData){
    return this._httpClient.post(`${API_ENDPOINT}/conversations/${chatID}/messages`, this.FileService.toFormData(messageData), {
      ...this._passportService.httpOptions
    }).pipe(
        map(
          (response)=>{
            return response['data'];
          }
        )
    );
  }
}
