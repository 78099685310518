import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-profile-country-select-field',
  templateUrl: './country-select.component.html',
  styleUrls: ['./country-select.component.css'],
})
export class ProfileCountrySelectComponent implements OnInit {
  @Input('value') initValue: number;
  @Input('key') key: string;
  @Output('updated') updatedEvent: EventEmitter<object> = new EventEmitter<
    object
  >();
  @Input() only: Array<object>;
  public value: number;
  public textValue: string = '';
  private _isShowEditField: boolean = false;

  constructor() {}

  ngOnInit() {
    this.value = this.initValue;
  }

  setEditFieidState(show: boolean): void {
    this._isShowEditField = show;
  }

  change(value) {
    this.value = value;
    this.update();
  }

  cancel() {
    this.value = this.initValue;
  }

  update() {
    if (this.value && this.key) {
      this.updatedEvent.emit({
        [this.key]: this.value,
      });
      this.setEditFieidState(false);
    }
  }

  hasInit(): boolean {
    return !!this.initValue;
  }

  isShowEditField(): boolean {
    return this._isShowEditField;
  }

  isShowField(): boolean {
    return !this._isShowEditField;
  }
}
