import { Injectable } from '@angular/core';
import { API_ENDPOINT } from "../app.settings";
import { PassportService } from "./passport.service";
import { map, catchError } from 'rxjs/operators';
import { HttpClient, HttpParams } from '@angular/common/http';
import { QuestionService } from "./question.service";
import { IQuestion, QuestionViewOption } from '../models/question/question.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ClientService {

  constructor(
    private _passportService:PassportService,
    private _httpClient:HttpClient,
    private _questionService:QuestionService
  ) { }

  acceptAnswer(questionID:number):Observable<{question:IQuestion, meta:QuestionViewOption}>{
    const params = new HttpParams().set('status', QuestionService.STATUS_COMPLETED);
    return this._questionService.update(questionID, params).pipe(map(
      (data)=>{
        return {
          question:<IQuestion>data['data'],
          meta:<QuestionViewOption>data['meta']
        }
      }
    ));
  }

  cancelAnswer(questionID:number):Observable<{question:IQuestion, meta:QuestionViewOption}>{
    const params = new HttpParams().set('status', QuestionService.STATUS_CANCELLED);
    return this._questionService.update(questionID, params).pipe(map(
      (data)=>{
        return {
          question:<IQuestion>data['data'],
          meta:<QuestionViewOption>data['meta']
        }
      }
    ));
  }

  rejectAnswer(questionID:number):Observable<{question:IQuestion, meta:QuestionViewOption}>{
    const params = new HttpParams().set('status', QuestionService.STATUS_ANSWER_REJECTED);
    return this._questionService.update(questionID, params).pipe(map(
      (data)=>{
        return {
          question:<IQuestion>data['data'],
          meta:<QuestionViewOption>data['meta']
        }
      }
    ));
  }
}
