import { reducer, appReducerState } from "./appReducer"
import { ActionReducerMap } from "@ngrx/store";
import { ChatState, chatReducer }  from "./chat.reducer";
import { UiState, uiReducer } from "./ui.reducer";
import { UserState } from "./user";
import { reducer as userReducer } from './user';


interface AppState {
    appReducer:appReducerState,
    chat:ChatState,
    user:UserState,
    ui:UiState
}

export const reducers: ActionReducerMap<AppState> = {
    appReducer:reducer,
    chat:chatReducer,
    user:userReducer,
    ui:uiReducer
};
