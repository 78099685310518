import { Component, OnInit, Input } from '@angular/core';
import { ISystemMessage } from 'src/app/models/chat/system-message.model';

@Component({
  selector: 'app-agent-system-message',
  templateUrl: './system-message.component.html',
  styleUrls: ['./system-message.component.css']
})
export class AgentSystemMessageComponent implements OnInit {
  @Input('message') message:ISystemMessage;
  constructor() { }

  ngOnInit() {
  }

}
