import { Injectable } from '@angular/core';
import { PassportService } from './passport.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { API_ENDPOINT } from '../app.settings';
import { map } from 'rxjs/operators';
import { IQuestion, QuestionViewOption } from '../models/question/question.model';

@Injectable({
  providedIn: 'root'
})
export class RejectMessageService {
  constructor(
    private _passportService:PassportService,
    private _httpService:HttpClient
  ) { }

  public store(data):Observable<{question:IQuestion, meta:QuestionViewOption}>{
    return this._httpService.post(API_ENDPOINT+"/reject-message", data, this._passportService.httpOptions).pipe(
      map((response)=>{
        return {
          question:response['data'],
          meta:response['meta']
        };
      })
    );
  }
}
