import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { IMessage } from "../../../../models/chat/message.model";
import { ChatState } from 'src/app/store/reducers/chat.reducer';
import { Store } from '@ngrx/store';
import { SetChatState } from 'src/app/store/actions/chat.action';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.css']
})
export class ClientMessageComponent implements OnInit {
  @Input('message') message:IMessage;
  @Output('rejected') rejectEvent:EventEmitter<void> = new EventEmitter<void>();
  @Input('canReject') canReject:boolean = false;
  constructor(
  ) { }

  ngOnInit() {
  }

  isReject(){
    return !this.message.isMine && this.canReject;
  }

  rejectAnswer(){
    this.rejectEvent.emit();
  }


}
