import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-tree-selected',
  templateUrl: './tree-selected.component.html',
  styleUrls: ['./tree-selected.component.css']
})
export class TreeSelectedComponent {
  @Input() value:string;
  @Input('isOpen') isOpen:boolean = false;
  @Output() valueChange = new EventEmitter<string>();
  @Input() placeholder = 'Category'; 
  constructor() {}

  onValueChange(model: string|null){
      this.value = model;
      this.valueChange.emit(model);
  }

}
