import { Component, OnInit, Input, Inject, ViewChild, ViewContainerRef } from '@angular/core';
import { AgentService } from "../../../../services/agent.service";
import { IQuestion } from "../../../../models/question/question.model";
import { Router } from '@angular/router';


@Component({
  selector: 'app-agent-question-pool-item',
  templateUrl: './question-pool-item.component.html',
  styleUrls: ['./question-pool-item.component.css']
})
export class AgentQuestionPoolItemComponent {
  @Input('question') item;
  @ViewChild('container', { read: ViewContainerRef }) container: ViewContainerRef;

  private question:IQuestion;
  constructor(
    @Inject('paginateItemData') private data,
    private _agentService:AgentService,
    private _router:Router
  ) {
    this.item = data;
  }

  attachQuestion(){
    this._agentService.attachToQuestion({question_id:this.item.id}).subscribe(
      (question:IQuestion)=>{
        this.question = question;
        this._router.navigateByUrl(this._router.createUrlTree(this.getRouterLink()));
      }
    )
  }

  getRouterLink(){
      return [
        '/agent/dashboard/questions', this.question.id, 'chats', this.question.activeConversation['id']
      ];
  }

}
