import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter,
} from '@angular/core';
import { IPayPalConfig } from 'ngx-paypal';
import { API_ENDPOINT, PAYPAL_API_SETTING } from '../../../app.settings';
import { HttpClient } from '@angular/common/http';
import { PassportService } from 'src/app/services/passport.service';

declare var paypal;

@Component({
  selector: 'app-paypal-chekout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.css'],
})
export class PaypalCheckoutComponent implements OnInit {
  @Input() amount = 0;
  @Input() serverCreateTransactionURL = '';
  @ViewChild('paypal') paypalElement: ElementRef;
  @Output('paymentExecuted') checkoutEmit: EventEmitter<
    void
  > = new EventEmitter();

  public payPalConfig?: IPayPalConfig;

  ngOnInit(): void {
    // this.initConfig();
  }
  constructor(
    private _httpClient: HttpClient,
    private _passportService: PassportService
  ) {}

  ngAfterViewInit() {
    this.loadExternalScript(
      'https://www.paypalobjects.com/api/checkout.js'
    ).then((_) => {
      this.initConfig();
    });
  }

  private loadExternalScript(scriptUrl: string) {
    if (window['paypal']) {
      return Promise.resolve(null);
    }
    return new Promise((resolve, reject) => {
      const scriptElement = document.createElement('script');
      scriptElement.src = scriptUrl;
      scriptElement.onload = resolve;
      document.body.appendChild(scriptElement);
    });
  }

  private initConfig(): void {
    const self = this;
    paypal.Button.render(
      {
        env: PAYPAL_API_SETTING.mod,
        style: {
          color: 'blue',
          shape: 'pill',
          label: 'pay',
          height: 40,
        },
        payment: function (data, actions) {
          return self._httpClient
            .post(
              `${API_ENDPOINT}/users/current/paypal/payment`,
              {
                amount: self.amount,
              },
              self._passportService.httpOptions
            )
            .toPromise()
            .then(function (res) {
              return res['id'];
            });
        },
        onAuthorize: function (data, actions) {
          return self._httpClient
            .post(
              `${API_ENDPOINT}/users/current/paypal/execute-payment`,
              {
                paymentID: data.paymentID,
                payerID: data.payerID,
              },
              self._passportService.httpOptions
            )
            .toPromise()
            .then(function (res) {
              self.checkoutEmit.emit();
            });
        },
      },
      this.paypalElement.nativeElement
    );
  }
}
