import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AgentQuestionPoolItemComponent } from "./question-pool-item/question-pool-item.component";
import { QuestionService } from "../../../services/question.service";
import { Store } from '@ngrx/store';
import { UiState } from '../../../store/reducers/ui.reducer';
import { SetUiState, UnsetUiState } from '../../../store/actions/ui.action';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CategoryService } from 'src/app/services/category.service';
import { HttpParams } from '@angular/common/http';
import { Subject } from 'rxjs';


@Component({
  selector: 'app-agent-questions',
  templateUrl: './questions.component.html',
  styleUrls: ['./questions.component.css'],
  entryComponents:[AgentQuestionPoolItemComponent]
})
export class AgentQuestionsComponent implements OnInit {
  public searchForm:FormGroup;
  private _treeValues:Array<object>;
  public questionsSubject: Subject<void> = new Subject<void>();

  constructor(
    public questionService:QuestionService,
    private _uiStore:Store<UiState>,
    private _categoryService:CategoryService,
    private _fb: FormBuilder
  ) {
    _uiStore.dispatch(new SetUiState({subTitle:"Question pool"}));
  }

  ngOnInit() {
    this.searchForm = this.initFilterForm(this._fb);
    this.initCategories();
    this.initValueChangesSubscribe(this.searchForm);
  }

  ngOnDestroy(){
    this._uiStore.dispatch(new UnsetUiState());
  }

  paginateComponentRef(){
    return AgentQuestionPoolItemComponent;
  }

  initFilterForm(fb:FormBuilder):FormGroup{
    return fb.group({
      search:[''],
      country:[null],
      category:[null],
      sort:[null],
    })
  }

  changeForm(){
    this.questionsSubject.next();
  }

  get treeValues(){
    return this._treeValues;
  }

  initSortObject(){
    return [
      {id:"time-asc", name:"Oldest"},
      {id:"time-desc", name:"Most Recent"},
      {id:"name-asc", name:"Name (A-Z)"},
      {id:"name-desc", name:"Name (Z-A)"},
    ];
  }

  initValueChangesSubscribe(form:FormGroup){
    // form.get('country').valueChanges.subscribe( () => {
    //   this.changeForm();

    // })
    // form.get('category').valueChanges.subscribe( () => {
    //   this.changeForm();
    // })
    // form.get('sort').valueChanges.subscribe( () => {
    //   this.changeForm();
    // })
  }

  initCategories(){
    this._categoryService.categoriesTreeObservable(
      {
        id:'id',
        title:'name',
        recursive_tree:'child'
      }).subscribe(
      (tree:Array<object>)=>{
        this._treeValues = tree
      }
    );
  }

  questions(params:HttpParams){
    params = this.toQuery(params, this.searchForm.value);
    return this.questionService.questions(params);
  }

  toQuery(params:HttpParams, obj):HttpParams{
    Object.keys(obj).map(function(key) {
      const value = obj[key];
      if(value){
        params = params.append(key, value);
      }
    });
    return params;
  }
}
