import { Directive, HostListener, HostBinding, ElementRef } from '@angular/core';

@Directive({
  selector: '[appHeaderMenuItem]'
})
export class HeaderMenuItemDirective {
  
  @HostBinding('class.active') private isActive:boolean = false;

  constructor(private el: ElementRef) { }

  @HostListener("mouseover", ["$event"]) onMouseOver(event) {
    this.isActive = true;
  }
  @HostListener("mouseleave", ["$event"]) onMouseLeave(event) {
    this.isActive = false;
  }

}
