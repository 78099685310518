import { Component, Output, EventEmitter } from '@angular/core';
import { Subject } from 'rxjs';
import { CardService } from '../../../services/card.service';


@Component({
  selector: 'stripe-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.css']
})
export class ModalComponent{
  public stripeCheck: Subject<object> = new Subject<object>();
  @Output('addedCard') addedCard:EventEmitter<void> = new EventEmitter();

  constructor(
    private _cardService:CardService
  ) { }

  stripeAddCard(token:string):void{
    this._cardService.addCard(token)
    .subscribe(
      (hasCard:boolean)=>{
        hasCard && this.addCardHandler();
      }
    )
  }

  addCardHandler():void{
    this.addedCard.emit();
  }

  addCard():void{
    this.emitEventStripeCheck();
  }

  emitEventStripeCheck(options:object = {}):void {
    this.stripeCheck.next(options);
  }

}
