import { Injectable } from "@angular/core";
import { PassportService } from "./passport.service";
import { HttpClient } from "@angular/common/http";
import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";

import { API_ENDPOINT } from "../app.settings";

@Injectable({
  providedIn: "root"
})
export class StatusService {
  constructor(
    private _passportService: PassportService,
    private _httpClient: HttpClient
  ) {}

  question():Observable<any>{
    return this._httpClient.get(API_ENDPOINT+`/statuses/questions`, this._passportService.httpOptions);
  }
}
