import { Component, OnInit, Input } from '@angular/core';
import { IMessage } from "../../../../models/chat/message.model";

@Component({
  selector: 'app-agent-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.css']
})
export class AgentMessageComponent {
  @Input('message') message:IMessage;
  constructor() { }

}
