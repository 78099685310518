import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  ElementRef,
} from '@angular/core';
import {
  FormGroup,
  FormControl,
  Validators,
  AbstractControl,
} from '@angular/forms';
import { HttpParams } from '@angular/common/http';
import { QuestionStoryItemComponent } from './questions-story/question-story-item/question-story-item.component';
import { CategoryService } from '../../../services/category.service';
import { QuestionService } from '../../../services/question.service';
import { Subject } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { UiState } from 'src/app/store/reducers/ui.reducer';
import { SetUiState, UnsetUiState } from 'src/app/store/actions/ui.action';
import { AuthService } from 'src/app/services/auth.service';
import { map } from 'rxjs/operators';
import { IQuestion } from 'src/app/models/question/question.model';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { SettingService } from 'src/app/services/setting.service';
import { IUser } from '../../../models/user/user.model';
import { UserState } from 'src/app/store/reducers/user';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-questions',
  templateUrl: './questions.component.html',
  styleUrls: ['./questions.component.css'],
  entryComponents: [QuestionStoryItemComponent],
})
export class ClientQuestionsComponent implements OnInit, OnDestroy {
  public currentUser: IUser;
  private _treeValues: Array<object>;
  public dragFiles: Array<File> = [];
  public questionsSubject: Subject<void> = new Subject<void>();
  public draftQuestion: IQuestion;
  private _localStorageQuestionKey = 'draft_question';
  public questionMinPrice = 0;
  private _insufficientBalanceErrorModal: NgbModalRef;
  @ViewChild('insufficientBalanceErrorModalDOM')
  insufficientBalanceErrorModalDOM: ElementRef;
  public amountData: { amount: number; balance: number };

  public questionForm: FormGroup;

  constructor(
    private _categoryService: CategoryService,
    public questionService: QuestionService,
    private _authService: AuthService,
    private _uiStore: Store<UiState>,
    private _localStorageService: LocalStorageService,
    private _setttingService: SettingService,
    private _userStore: Store<UserState>,
    private _modalService: NgbModal
  ) {
    _uiStore.dispatch(new SetUiState({ subTitle: 'Dashboard' }));
    this._userStore.pipe(select('user')).subscribe((data: UserState) => {
      this.currentUser = data.activeUser;
    });
  }

  ngOnInit() {
    this.questionForm = this.initNewQuestionForm();

    this._categoryService
      .categoriesTreeObservable({
        id: 'id',
        title: 'name',
        recursive_tree: 'child',
      })
      .subscribe((tree: Array<object>) => {
        this._treeValues = tree;
      });

    this.draftQuestions();
    this._setttingService
      .settingObservable(SettingService.SETTING_SLUGS['question-min-price'])
      .subscribe((setting: number) => {
        this.questionMinPrice = setting;
      });
  }

  ngOnDestroy() {
    this._uiStore.dispatch(new UnsetUiState());
  }

  addQuestion(fields) {
    this._localStorageService.setItem(this._localStorageQuestionKey, fields);
    fields['files[]'] = this.dragFiles;
    this.questionService.store(fields).subscribe(
      () => {
        this.questionForm.reset();
        this.questionsSubject.next();
        this._authService.currentUserObservable();
        this.dragFiles = [];
        this._localStorageService.remove(this._localStorageQuestionKey);
      },
      (error) => {
        const errors = error && error.error.errors;
        if (errors['price']) {
          this.amountData = {
            amount: fields['price'],
            balance: this.currentUser.balance,
          };
          this._insufficientBalanceErrorModal = this._modalService.open(
            this.insufficientBalanceErrorModalDOM,
            {
              ariaLabelledBy: 'modal-basic-title',
            }
          );
        }
      }
    );
  }

  updateQuestion(fields, question: IQuestion) {
    this._localStorageService.setItem(this._localStorageQuestionKey, fields);
    fields['files[]'] = this.dragFiles;
    this.questionService
      .publish(
        question.id,
        new HttpParams({ fromObject: fields }).append(
          'status',
          QuestionService.STATUS_NEW
        )
      )
      .subscribe(
        (data) => {
          this.questionForm.reset();
          this.questionsSubject.next();
          this._authService.currentUserObservable();
          this.draftQuestions();
          this.dragFiles = [];
          this._localStorageService.remove(this._localStorageQuestionKey);
        },
        (error) => {
          const errors = error && error.error.errors;
          if (errors['price']) {
            this.amountData = {
              amount: fields['price'],
              balance: this.currentUser.balance,
            };
            this._insufficientBalanceErrorModal = this._modalService.open(
              this.insufficientBalanceErrorModalDOM,
              {
                ariaLabelledBy: 'modal-basic-title',
              }
            );
          }
        }
      );
  }

  filesHandler(event) {
    this.questionForm.patchValue({ 'files[]': event.target.files });
  }

  get treeValues() {
    return this._treeValues;
  }

  balanceUpdated(): void {
    const fields = this.questionForm.value;
    this.questionButtonHandler(fields);
  }

  paginateComponentRef() {
    return QuestionStoryItemComponent;
  }

  draftQuestions() {
    this.questionService
      .clientQuestionsStory(
        new HttpParams().append('status', QuestionService.STATUS_DRAFT)
      )
      .pipe(map((data) => data.data))
      .subscribe((questions: Array<IQuestion>) => {
        this.draftQuestion = questions.shift();
        if (this.draftQuestion) {
          this.setDraftQuestionForm(this.questionForm, this.draftQuestion);
        }
      });
  }

  isUpdated() {
    if (this.draftQuestion) {
      return true;
    }
    return false;
  }

  questionButtonHandler(fields) {
    if (this.isUpdated()) {
      this.updateQuestion(fields, this.draftQuestion);
    } else {
      this.addQuestion(fields);
    }
    return false;
  }

  questionButtonText(): string {
    return this.isUpdated() ? 'Publish' : 'Ask now';
  }

  initNewQuestionForm(): FormGroup {
    const storageQuestionData = this._localStorageService.getItem(
      this._localStorageQuestionKey
    )
      ? JSON.parse(
          this._localStorageService.getItem(this._localStorageQuestionKey)
        )
      : [];

    return new FormGroup({
      title: new FormControl(
        storageQuestionData['title'] || null,
        Validators.required
      ),
      description: new FormControl(
        storageQuestionData['description'] || null,
        Validators.required
      ),
      price: new FormControl(storageQuestionData['price'] || null, [
        Validators.required,
        Validators.min(this.questionMinPrice),
      ]),
      category_id: new FormControl(
        storageQuestionData['category_id'] || null,
        Validators.required
      ),
      destination_country_id: new FormControl(
        storageQuestionData['destination_country_id'] || null,
        Validators.required
      ),
      agent_code: new FormControl(
        storageQuestionData['agent_code'] || '',
        Validators.maxLength(6)
      ),
      'files[]': new FormControl(null),
    });
  }

  setDraftQuestionForm(form: FormGroup, question: IQuestion) {
    form.patchValue({ title: question.title });
  }

  checkIsValid(control: AbstractControl): boolean {
    return control.invalid && (control.dirty || control.touched);
  }

  errorByName(control: AbstractControl, slug: string) {
    return control.errors[slug] ? control.errors[slug].value : '';
  }

  clientInsufficientBalancePopupClosed(): void {
    if (this._insufficientBalanceErrorModal) {
      this._insufficientBalanceErrorModal.close();
    }
  }
}
