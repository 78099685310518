import { Injectable } from '@angular/core';
import { PassportService } from './passport.service';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';


import { API_ENDPOINT } from "../app.settings";


@Injectable({
  providedIn: 'root'
})
export class SettingService {
  static SETTING_SLUGS = {
    'question-min-price':'default-question-price'
  };
  constructor(
    private _passportService:PassportService,
    private _httpClient:HttpClient       
  ) { }
  settingObservable(setting):Observable<string|number|boolean>{
    return this._httpClient.get(
      API_ENDPOINT+`/settings/${setting}`,
      this._passportService.httpOptions)
      .pipe(map((setting:string|number|boolean)=>{
        return setting;
      }));
  }

}
