import { Component } from '@angular/core';
import { AuthService } from '../../../services/auth.service';
import { Router } from '@angular/router';
import { AppService } from '../../../services/app.service';

@Component({
  selector: 'app-agent-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class AgentLoginComponent {
  public globalResponseError: string;

  constructor(
    private _authService: AuthService,
    private _appService: AppService,
    private _router: Router
  ) {}

  onLoginUser(loginData: any) {
    this._authService.loginObservable(loginData.value, 'agent').subscribe(
      (user) => {
        this._router.navigate(['/agent/dashboard']);
      },
      (errorResponse) => {
        const errors: Array<string> = this._appService.getError(errorResponse);
        this.globalResponseError = errors['global'] || '';
        this._appService.setFormGroupErrors(loginData, errors);
        this._appService.showGlobalError(errors);
      }
    );
  }
}
