import { Injectable } from '@angular/core';
import { PassportService } from './passport.service';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { API_ENDPOINT } from "../app.settings";
import { isArray } from 'util';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {

  constructor(
    private _passportService:PassportService,
    private _httpClient:HttpClient
  ) { }

  categoriesTreeObservable(fields):Observable<Array<any>>{
    return this._httpClient.get(API_ENDPOINT+'/categories/tree',this._passportService.httpOptions)
    .pipe(
      map((categories) => {
        return this.categoryRecursion(categories, fields);
      }),
      catchError(()=>{
        return of(new Array);
      })
    );
  }


  categoriesObservable(fields):Observable<Array<any>>{
    return this._httpClient.get(API_ENDPOINT+'/categories/list',this._passportService.httpOptions)
    .pipe(
      map((categories) => {
        return this.categoryRecursion(categories, fields);
      }),
      catchError(()=>{
        return of(new Array);
      })
    );
  }

  categoryRecursion(categories, fields){
    return Object.values(categories).map((category)=>{
      let response = Object.create(null);
      for (const field in fields) {
        if (fields.hasOwnProperty(field)) {
          let item = category[field];
          if(isArray(item) && item.length){
            item = this.categoryRecursion(item, fields);
          }
            response[fields[field]] = item
        }
      }
      return response;
    });
  }




}
