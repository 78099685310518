import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'empty'
})
export class EmptyPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    for(var prop in value) {
      if(value.hasOwnProperty(prop))
        return false;
    }
    return true;
  }

}
