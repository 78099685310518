import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Subject } from 'rxjs';
import { CardService } from 'src/app/services/card.service';

@Component({
  selector: 'app-agent-stripe-modal',
  templateUrl: './agent-modal.component.html',
  styleUrls: ['./agent-modal.component.css'],
})
export class AgentStripeModalComponent {
  public stripeCheck: Subject<object> = new Subject<object>();
  @Output('addedCard') addedCard: EventEmitter<void> = new EventEmitter();

  constructor(private _cardService: CardService) {}

  stripeAddCard(token: string): void {
    this._cardService.storeExternalAccount(token).subscribe((card) => {
      // console.log(card);
    });
  }

  addCardHandler(): void {
    this.addedCard.emit();
  }

  addCard(): void {
    this.emitEventStripeCheck();
  }

  emitEventStripeCheck(options: object = {}): void {
    this.stripeCheck.next(options);
  }
}
