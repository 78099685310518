import { Component, OnInit, Input, Inject } from '@angular/core';
import { IQuestion } from 'src/app/models/question/question.model';

//bad component -=======================================================================================



interface ITransaction{
  amount:number,
  created:string,
  balance:number,
  question?:IQuestion,
  fee:number,
  isProfile():boolean,
  calcResultIncome():number
}

class Transaction implements ITransaction{
  amount:number;
  created:string;
  balance:number;
  question?:IQuestion;
  fee:number;

  constructor(fields:Object){
    this.amount   = fields['amount'] || 0;
    this.created  = fields['created_at'];
    this.balance  = (fields['balance_snapshot'] || fields['user_to_balance_snapshot']) || 0;
    this.question = fields['question'] || null;
    this.fee      = fields['fee'] || 0;
  }
  isProfile():boolean{
    return !!!this.question;
  }

  calcResultIncome():number{
    return this.isProfile() ? -(this.amount - this.fee) : (this.amount - this.fee);
  }
}




@Component({
  selector: 'app-agent-wallet-transaction',
  templateUrl: './transaction.component.html',
  styleUrls: ['./transaction.component.css']
})
export class AgentWalletTransactionComponent implements OnInit {
  @Input('data') transaction:ITransaction;
  constructor( @Inject('paginateItemData') private data ) {
    this.transaction = new Transaction(data);
  }

  ngOnInit() {
  }

  showBalance(){
    return this.transaction.balance;
  }

}
