import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Observable } from 'rxjs';
import {
  StripeService,
  Elements,
  Element as StripeElement,
  ElementsOptions,
  TokenResult,
  CardDataOptions,
} from 'ngx-stripe';
import { ToastrService } from 'ngx-toastr';
import { STRIPE_CURRENCY } from '../../app.settings';

@Component({
  selector: 'app-stripe',
  templateUrl: './stripe.component.html',
  styleUrls: ['./stripe.component.css'],
})
export class StripeComponent implements OnInit {
  elements: Elements;
  card: StripeElement;

  @Output('token') token: EventEmitter<string> = new EventEmitter();
  @Input('check') check: Observable<object> = new Observable<object>();
  @Input('classes') classes: string = '';

  elementsOptions: ElementsOptions = {
    locale: 'en',
  };

  cardOptions: CardDataOptions = {
    currency: STRIPE_CURRENCY,
  };

  stripe: FormGroup;

  constructor(
    private fb: FormBuilder,
    private stripeService: StripeService,
    private _toastrService: ToastrService
  ) {}

  ngOnInit() {
    this.check.subscribe((option: object) => {
      this.checkStripeData(option).subscribe((response: TokenResult) => {
        if (response.token) {
          this.token.next(response.token.id);
        } else if (response.error) {
          this._toastrService.error(response.error.message);
        }
      });
    });
    this.stripeService.elements(this.elementsOptions).subscribe((elements) => {
      this.elements = elements;
      if (!this.card) {
        this.card = this.elements.create('card', {
          style: {
            base: {
              color: '#32325d',
              lineHeight: '40px',
              fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
              fontSmoothing: 'antialiased',
              fontSize: '16px',
              '::placeholder': { color: '#aab7c4' },
            },
            invalid: { color: '#fa755a', iconColor: '#fa755a' },
          },
        });
        this.card.mount('#card-stripe-uniq');
      }
    });
  }

  checkStripeData(options: object) {
    return this.stripeService.createToken(this.card, {
      ...options,
      ...this.cardOptions,
    });
  }
}
