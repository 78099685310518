import { Component, Input } from "@angular/core";
import { IReview } from "src/app/models/review/review.model";

@Component({
  selector: "app-agent-review-item",
  templateUrl: "./review-item.component.html",
  styleUrls: ["./review-item.component.css"]
})
export class AgentReviewItemComponent {
  @Input() review: IReview;
  constructor() {}
}
