import { Component, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { UiState } from '../../../store/reducers/ui.reducer';
import { UnsetUiState } from '../../../store/actions/ui.action';
import { OnDestroy } from '@angular/core';
import { IUser } from '../../../models/user/user.model';
import { CardService } from '../../../services/card.service';
import { UserService } from '../../../services/user.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from '../../../services/auth.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { isEmpty } from '../../../helpers/object';
import { Subject } from 'rxjs';
import { UserState } from 'src/app/store/reducers/user';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-agent-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css'],
})
export class AgentProfileComponent implements OnInit, OnDestroy {
  public stripeCheck: Subject<object> = new Subject<object>();
  public currentUser: IUser;
  public is = isEmpty;
  public isAboutEdit: boolean;
  private _activeStripeModal: NgbActiveModal;
  public defaultCart: object;

  constructor(
    private _authService: AuthService,
    private _modalService: NgbModal,
    private _userService: UserService,
    private _cardService: CardService,
    private _uiStore: Store<UiState>,
    private _userStore: Store<UserState>,
    private _toastrService: ToastrService
  ) {}

  ngOnInit() {
    this._userStore.pipe(select('user')).subscribe((data: UserState) => {
      this.currentUser = data.activeUser;
      this.currentUser.specialUserData.categoriesID = data.activeUser.specialUserData.categories.map(
        (item) => item['id']
      );
    });
    this._cardService.initDefaultCard();

    this._cardService.defaultCard.subscribe((card: object) => {
      this.defaultCart = card;
    });
  }

  ngOnDestroy() {
    this._uiStore.dispatch(new UnsetUiState());
  }

  openStripeModal(content) {
    this._activeStripeModal = this._modalService.open(content, {
      ariaLabelledBy: 'modal-basic-title',
    });
  }

  async addedStripeCard() {
    await this._authService.currentUserObservable();
    this._cardService.initDefaultCard();
    this._activeStripeModal.close();
  }

  updateField(event) {
    this._userService.update(event).subscribe((data) => {
      this._authService.currentUserObservable();
    });
  }

  avatarFileUpload(file: File): void {
    const filesForm = new FormData();
    filesForm.append('avatar', file);
    this.updateField(filesForm);
  }

  copied() {
    this._toastrService.success('Copied');
  }

  updateAboutField(aboutElement) {
    const { value } = aboutElement;
    if (!value) {
      return this.onAboutBlur();
    }
    this.updateField({ about: value });
    this.onAboutBlur();
  }

  onAboutBlur() {
    this.isAboutEdit = false;
  }

  onAboutFocus() {
    this.isAboutEdit = true;
  }
}
