import { Component, OnInit, Input, AfterViewInit } from "@angular/core";
import { IUser } from "src/app/models/user/user.model";
import { ReviewService } from "src/app/services/review.service";
import { IReview } from "src/app/models/review/review.model";
import { HttpParams } from "@angular/common/http";
import { Subscription } from "rxjs/internal/Subscription";

@Component({
  selector: "app-agent-view-profile",
  templateUrl: "./view-profile.component.html",
  styleUrls: ["./view-profile.component.css"]
})
export class AgentViewProfileComponent implements OnInit, AfterViewInit {
  @Input() user: IUser;
  public reviews: Array<IReview> = new Array();
  private subscription: Subscription;
  public reviewsOption;
  private _currentPage: number = 1;
  private _lastPage = 1;
  constructor(private _reviewService: ReviewService) {}

  ngAfterViewInit() {

  }

  ngOnInit() {
    this.uploadReviews(this.user.id, this._currentPage);
  }

  uploadReviews(userID: number = 1, page: number) {
    let params = new HttpParams()
      .set("userID", userID.toString())
      .set("page", page.toString());
    setTimeout(
      //only ST works. It`s a  ng-bootstrap's bug
      () => {
        this.subscription = this._reviewService
          .showByUser(params)
          .subscribe((data: { reviews: Array<IReview>; meta: any }) => {
            this.reviews.push(...data.reviews);
            this._lastPage = data.meta["last_page"];
            this.reviewsOption = {
              count: data.meta["count"],
              rating: data.meta["rating"]
            };
          });
      }
    );
  }

  loadMore() {
    this.uploadReviews(this.user.id, ++this._currentPage);
  }

  isShowLoadMore() {
    return this._currentPage < this._lastPage;
  }

  public ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  public lastOnline(){
    if(this.user.lastOnline){
      return `${this.user.lastOnline}h ago`;
    }
    return 'Online';
  }
}
