import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, Injector } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxLoadingModule } from 'ngx-loading';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {
  NgbPaginationModule,
  NgbAlertModule,
} from '@ng-bootstrap/ng-bootstrap';
import { ClipboardModule } from 'ngx-clipboard';
import { NgxPaginationModule } from 'ngx-pagination';
import { ToastrModule, ToastrService } from 'ngx-toastr';
import { ngfModule, ngf } from 'angular-file';
import { NgxPayPalModule } from 'ngx-paypal';
import { FlexLayoutModule } from '@angular/flex-layout';

import { AppComponent } from './app.component';
import { StoreModule } from '@ngrx/store';
import { reducers } from './store/reducers/index';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { TreeviewModule } from 'ngx-treeview';
import { NgxStripeModule } from 'ngx-stripe';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import {
  NgxUiLoaderHttpModule,
  NgxUiLoaderRouterModule,
  NgxUiLoaderConfig,
} from 'ngx-ui-loader';
// import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
// settings
import { STRIPE_API_KEY } from './app.settings';

// services
import { PassportService } from './services/passport.service';
import { UserService } from './services/user.service';
import { AppService } from './services/app.service';
import { HttpErrorInterceptor } from './interceptors/http-error.interceptor';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { AuthService } from './services/auth.service';

//client
import { ClientDashboardSideBarComponent } from './client-components/dashboard/dashboard-side-bar/dashboard-side-bar.component';
import { ClientDashboardSubHeaderComponent } from './client-components/dashboard/dashboard-sub-header/dashboard-sub-header.component';
import { ClientWalletComponent } from './client-components/dashboard/wallet/wallet.component';
import { ClientDasboardPageComponent } from './client-components/dashboard/client-dasboard-page/client-dasboard-page.component';
import { ClientLoginComponent } from './client-components/auth/login/login.component';
import { ClientSignupComponent } from './client-components/auth/signup/client.component';
import { ClientDashbourdComponent } from './client-components/dashboard/dashbourd.component';
import { ClientProfileComponent } from './client-components/dashboard/profile/profile.component';
import { ClientQuestionsComponent } from './client-components/dashboard/questions/questions.component';
import { QuestionStoryItemComponent } from './client-components/dashboard/questions/questions-story/question-story-item/question-story-item.component';
import { ClientInboxQuestionsComponent } from './client-components/dashboard/inbox-questions/inbox-questions.component';
import { ClientChatComponent } from './client-components/dashboard/chat/chat.component';
import { ClientChatAreaComponent } from './client-components/dashboard/chat/chat-area/chat-area.component';
import { ClientMessageComponent } from './client-components/dashboard/chat/message/message.component';
import { ClientChatQuestionComponent } from './client-components/dashboard/chat/chat-question/chat-question.component';
import { ClientMiniProfileComponent } from './client-components/dashboard/mini-profile/mini-profile.component';
import { ClientReviewComponent } from './client-components/dashboard/chat/review/review.component';
import { ClientRejectFormComponent } from './client-components/dashboard/chat/review/reject-form/reject-form.component';
import { ClientForgetPassComponent } from './client-components/auth/forget-pass/forget-pass.component';
import { ClientSystemMessageComponent } from './client-components/dashboard/chat/system-message/system-message.component';
import { AuthConfirmedComponent } from './client-components/auth/auth-confirmed/auth-confirmed.component';
import { ProfileTransactionComponent } from './client-components/dashboard/wallet/profile-transaction/profile-transaction.component';
import { CheckEmailClientComponent } from './client-components/auth/check-email/client.component';
import { AddExtraCreditComponent } from './client-components/dashboard/add-extra-credit/add-extra-credit.component';
import { ClientChangePasswordComponent } from './client-components/dashboard/change-password/change-password.component';

//agent
import { AgentSignupComponent } from './agent-components/auth/signup/signup.component';
import { AgentLoginComponent } from './agent-components/auth/login/login.component';
import { AgentAttachedQuestionsComponent } from './agent-components/dashboard/attached-questions/attached-questions.component';
import { AgentQuestionPoolItemComponent } from './agent-components/dashboard/questions/question-pool-item/question-pool-item.component';
import { AgentQuestionsComponent } from './agent-components/dashboard/questions/questions.component';
import { AgentSideBarComponent } from './agent-components/dashboard/side-bar/side-bar.component';
import { AgentDashboardComponent } from './agent-components/dashboard/dashboard.component';
import { AgentSubHeaderComponent } from './agent-components/dashboard/sub-header/sub-header.component';
import { AgentAttachedQuestionItemComponent } from './agent-components/dashboard/attached-questions/item/item.component';
import { AgentChatAreaComponent } from './agent-components/dashboard/chat/chat-area/chat-area.component';
import { AgentChatQuestionComponent } from './agent-components/dashboard/chat/chat-question/chat-question.component';
import { AgentMessageComponent } from './agent-components/dashboard/chat/message/message.component';
import { AgentChatComponent } from './agent-components/dashboard/chat/chat.component';
import { AgentMiniProfileComponent } from './agent-components/dashboard/mini-profile/mini-profile.component';
import { AgentReviewComponent } from './agent-components/dashboard/chat/review/review.component';
import { AgentRejectMessageComponent } from './agent-components/dashboard/chat/reject-message/reject-message.component';
import { AgentWalletComponent } from './agent-components/dashboard/wallet/wallet.component';
import { AgentWalletTransactionComponent } from './agent-components/dashboard/wallet/transaction/transaction.component';
import { AgentProfileComponent } from './agent-components/dashboard/profile/profile.component';
import { AgentViewProfileComponent } from './agent-components/dashboard/profile/view-profile/view-profile.component';
import { AgentReviewItemComponent } from './agent-components/dashboard/profile/view-profile/review-item/review-item.component';
import { AgentForgetPassComponent } from './agent-components/auth/forget-pass/forget-pass.component';
import { AgentSystemMessageComponent } from './agent-components/dashboard/chat/system-message/system-message.component';
import { RequestExtraCreditComponent } from './agent-components/dashboard/request-extra-credit/request-extra-credit.component';

// directives
import { InputFileDirective } from './directives/input-file.directive';
import { WhileDirective } from './directives/while.directive';
import { FileUploadDirective } from './directives/file-upload.directive';
import { HeaderMenuItemDirective } from './directives/header-menu-item.directive';

// pipes
import { EmptyPipe } from './pipes/empty.pipe';
import { FirstToUpperPipe } from './pipes/first-to-upper.pipe';
import { IfEmptyPipe } from './pipes/if-empty.pipe';
import { ToDateStampPipe } from './pipes/to-date-stamp.pipe';
import { TruncatePipe } from './pipes/truncate.pipe';

// components
import { AuthHeaderComponent } from './header/header.component';
import { SubHeaderComponent } from './header/sub-header/sub-header.component';
import { FooterComponent } from './footer/footer.component';
import { AppRoutingModule } from './app-routing.module';
import { MainBackgroundComponent } from './components/main-background/main-background.component';
import { CountrySelectComponent } from './components/custom-form-elements/country-select/country-select.component';
import { CheckboxAgreeComponent } from './components/custom-form-elements/checkbox-agree/checkbox-agree.component';
import { GlobalErrorComponent } from './components/global-error/global-error.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { CategorySelectComponent } from './components/custom-form-elements/category-select/category-select.component';
import { StripeComponent } from './components/stripe/stripe.component';
import { NumberInputComponent } from './components/custom-form-elements/number-input/number-input.component';
import { ModalComponent } from './components/stripe/modal/modal.component';
import { TreeSelectComponent } from './components/custom-form-elements/tree-select/tree-select.component';
import { TreeListComponent } from './components/custom-form-elements/tree-select/tree-list/tree-list.component';
import { ItemComponent } from './components/custom-form-elements/tree-select/tree-list/item/item.component';
import { TreeSelectedComponent } from './components/custom-form-elements/tree-select/tree-selected/tree-selected.component';
import { AsyncPaginateComponent } from './components/async-paginate/async-paginate.component';
import { FilesWrapperComponent } from './components/custom-form-elements/files-wrapper/files-wrapper.component';
import { ProfileImageComponent } from './components/profile-image/profile-image.component';
import { RatingComponent } from './components/custom-form-elements/rating/rating.component';
import { ProfileFieldComponent } from './components/profile-field/profile-field.component';
import { ProfileCountrySelectComponent } from './components/profile-field/country-select/country-select.component';
import { ProfileCategorySelectComponent } from './components/profile-field/category-select/category-select.component';
import { QuestionFilterComponent } from './components/question-filter/question-filter.component';
import { AgentStripeModalComponent } from './components/stripe/agent-modal/agent-modal.component';
import { PaypalCheckoutComponent } from './components/paypal/checkout/checkout.component';
import { TooltipComponent } from './components/tooltip/tooltip.component';
import { WhatIsCreditComponent } from './components/popups/what-is-credit/what-is-credit.component';
import { AnswerPriceComponent } from './components/popups/answer-price/answer-price.component';
import { AddCreditComponent } from './components/popups/client/add-credit/add-credit.component';
import { InsufficientBalanceComponent } from './components/popups/client/insufficient-balance/insufficient-balance.component';
import { FileInputComponent } from './components/custom-form-elements/file-input/file-input.component';
import { ApplyAgentCodeComponent } from './components/custom-form-elements/apply-agent-code/apply-agent-code.component';
import { ResetPasswordComponent } from './general/auth/reset-password/reset-password.component';
import { PasswordInputComponent } from './components/custom-form-elements/password-input/password-input.component';
import { AgentChangePasswordComponent } from './agent-components/dashboard/change-password/change-password.component';
import { SharingButtonsComponent } from './components/sharing-buttons/sharing-buttons.component';
const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  bgsColor: 'red',
  bgsSize: 40,
};

@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    ClientLoginComponent,
    MainBackgroundComponent,
    AuthHeaderComponent,
    ClientSignupComponent,
    CountrySelectComponent,
    CheckboxAgreeComponent,
    CheckEmailClientComponent,
    GlobalErrorComponent,
    AuthConfirmedComponent,
    NotFoundComponent,
    ClientDashbourdComponent,
    ClientProfileComponent,
    ClientQuestionsComponent,
    ClientDashboardSideBarComponent,
    ClientDasboardPageComponent,
    AgentSignupComponent,
    CategorySelectComponent,
    AgentLoginComponent,
    StripeComponent,
    ClientDashboardSubHeaderComponent,
    NumberInputComponent,
    ClientWalletComponent,
    FirstToUpperPipe,
    EmptyPipe,
    ProfileTransactionComponent,
    WhileDirective,
    ModalComponent,
    TreeSelectComponent,
    TreeListComponent,
    ItemComponent,
    TreeSelectedComponent,
    AsyncPaginateComponent,
    InputFileDirective,
    QuestionStoryItemComponent,
    ClientInboxQuestionsComponent,
    ClientChatComponent,
    ClientChatAreaComponent,
    ClientMessageComponent,
    ClientChatQuestionComponent,
    AgentQuestionsComponent,
    AgentSideBarComponent,
    AgentDashboardComponent,
    AgentSubHeaderComponent,
    AgentQuestionPoolItemComponent,
    AgentAttachedQuestionsComponent,
    AgentAttachedQuestionItemComponent,
    AgentChatAreaComponent,
    AgentChatQuestionComponent,
    AgentMessageComponent,
    AgentChatComponent,
    FilesWrapperComponent,
    AgentMiniProfileComponent,
    ProfileImageComponent,
    ClientMiniProfileComponent,
    ClientReviewComponent,
    RatingComponent,
    AgentReviewComponent,
    AgentRejectMessageComponent,
    AgentWalletComponent,
    AgentWalletTransactionComponent,
    ProfileFieldComponent,
    AgentProfileComponent,
    ProfileCountrySelectComponent,
    ProfileCategorySelectComponent,
    FileUploadDirective,
    ClientRejectFormComponent,
    IfEmptyPipe,
    AgentViewProfileComponent,
    AgentReviewItemComponent,
    ClientForgetPassComponent,
    AgentForgetPassComponent,
    QuestionFilterComponent,
    AddExtraCreditComponent,
    AgentSystemMessageComponent,
    ClientSystemMessageComponent,
    RequestExtraCreditComponent,
    AgentStripeModalComponent,
    SubHeaderComponent,
    HeaderMenuItemDirective,
    ToDateStampPipe,
    TruncatePipe,
    PaypalCheckoutComponent,
    TooltipComponent,
    WhatIsCreditComponent,
    AnswerPriceComponent,
    AddCreditComponent,
    InsufficientBalanceComponent,
    FileInputComponent,
    ApplyAgentCodeComponent,
    ResetPasswordComponent,
    ClientChangePasswordComponent,
    PasswordInputComponent,
    AgentChangePasswordComponent,
    SharingButtonsComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    StoreModule.forRoot(reducers, {}),
    FontAwesomeModule,
    TreeviewModule.forRoot(),
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    NgScrollbarModule,
    ClipboardModule,
    NgxLoadingModule.forRoot({}),
    NgxStripeModule.forRoot(STRIPE_API_KEY),
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    NgxUiLoaderHttpModule.forRoot({ showForeground: true }),
    NgxUiLoaderRouterModule,
    NgbModule,
    NgbPaginationModule,
    NgbAlertModule,
    NgxPaginationModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-top-right',
      enableHtml: false,
    }),
    ngfModule,
    NgxPayPalModule,
    FlexLayoutModule,
  ],
  providers: [
    AppService,
    { provide: 'PassportService', useClass: PassportService },
    UserService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
      deps: [Injector],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
      deps: [Injector],
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
