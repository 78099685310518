import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { API_ENDPOINT } from "../app.settings";
import { HttpHeaders } from '@angular/common/http';



@Injectable({
  providedIn: 'root'
})
export class PassportService {
  protected _headers = new HttpHeaders();
  public d = 45;
  constructor(
    private _httpClient:HttpClient
  ) {
    this._headers.set('Accept', 'application/json');
    this._headers.set('Authorization', `Bearer ${this.frontToken}`);
   }

  get httpOptions(){
    const headers = new HttpHeaders({
      'Accept':         'application/json',
      "Authorization":  `Bearer ${this.frontToken}`
    });
    return { headers };
  }

  get frontToken(){
    return localStorage.getItem('front_token') || '';
  }

  set frontToken(token){
    localStorage.removeItem('front_token');
    localStorage.setItem('front_token', token);
  }
}
