import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Injectable, Injector } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class HttpErrorInterceptor implements HttpInterceptor {
  private _toastrService: ToastrService;
  private _location: Location;
  constructor(private inj: Injector) {
    this._toastrService = inj.get(ToastrService);
    this._location = inj.get(Location);
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        const errors = error && error.error.errors;
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
          errorMessage = `Error: ${error.error.message}`;
        } else {
          errorMessage = error.error.message;
        }

        if (errors) {
          []
            .concat(...Object.values(errors))
            .forEach((item) => this._toastrService.error(item));
        }
        if (errorMessage) {
          this._toastrService.error(errorMessage);
        }

        // if (error.status === 404) {
        //   this._location.back();
        // }

        return throwError(error);
      })
    );
  }
}
