import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import Big from 'big.js';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CardService } from 'src/app/services/card.service';
import { CreditService } from 'src/app/services/credit.service';
import { Subject } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { isEmpty } from 'src/app/helpers/object';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-client-insufficient-balance-popup',
  templateUrl: './insufficient-balance.component.html',
  styleUrls: ['./insufficient-balance.component.css'],
})
export class InsufficientBalanceComponent implements OnInit {
  @Input() amountData: { amount: number; balance: number };
  @Output('closed') closed: EventEmitter<void> = new EventEmitter<void>();
  @Output('balanceUpdated') balanceUpdated: EventEmitter<
    void
  > = new EventEmitter<void>();
  public activePayment = 'paypal';
  public defaultCart: object;
  private _modal;
  public transactionSubject: Subject<void> = new Subject<void>();
  public stripeCheck: Subject<object> = new Subject<object>();

  constructor(
    private _modalService: NgbModal,
    public cardService: CardService,
    public creditService: CreditService,
    private _authService: AuthService,
    private _toastrService: ToastrService
  ) {}

  ngOnInit() {
    this.cardService.initDefaultCard();
    this.cardService.defaultCard.subscribe((card: object) => {
      this.defaultCart = card;
    });
  }

  payAmount(): number {
    const price: Big = new Big(this.amountData.amount);
    const balance: Big = new Big(this.amountData.balance);
    return price.minus(balance).toFixed(2);
  }

  currentBalance(): number {
    return new Big(this.amountData.balance).toFixed(2);
  }

  isUserBalanceEmpty(): boolean {
    return this.amountData.balance <= 0;
  }

  closePopup(): void {
    this.closed.next();
  }

  setActivePayment(val: string): void {
    this.activePayment = val;
  }

  isActivePayment(type: string): boolean {
    return this.activePayment === type;
  }

  open(content): void {
    this._modal = this._modalService.open(content, {
      ariaLabelledBy: 'modal-basic-title',
    });
  }

  stripeTokenHandles(token: string): void {
    this.cardService.addCard(token).subscribe((card: object) => {
      this._addCredit({ amount: this.payAmount() });
    });
  }

  private _addCredit(options: object): void {
    this.creditService.addCreditByDefaultCard(options).subscribe(
      () => {
        this.paymentExecutedHandler();
      },
      (error: HttpErrorResponse) => {
        const errMessage = error.error.message;
        if (errMessage) {
          this._toastrService.error(errMessage);
        }
      }
    );
  }

  paymentExecutedHandler(): void {
    this.transactionSubject.next();
    this._authService.currentUserObservable();
    this.cardService.initDefaultCard();
    this.balanceUpdated.next();
    this._toastrService.success('Payment successfully');
    this.closePopup();
  }

  addCredit(defaultCart: object): void {
    if (isEmpty(this.defaultCart)) {
      this.emitEventStripeCheck();
    } else {
      this._addCredit({ amount: this.payAmount() });
    }
  }

  emitEventStripeCheck(options: object = {}): void {
    this.stripeCheck.next(options);
  }

  async addedStripeCard() {
    await this._authService.currentUserObservable();
    this.cardService.initDefaultCard();
    this._modal.close();
  }
}
