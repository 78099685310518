import { Component, OnInit, Input, Inject, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { IChat } from "../../../../../models/chat/chat.model";
import { IQuestion } from 'src/app/models/question/question.model';



@Component({
  selector: 'app-question-story-item',
  templateUrl: './question-story-item.component.html',
  styleUrls: ['./question-story-item.component.css'],
  // changeDetection: ChangeDetectionStrategy.OnPush
})

export class QuestionStoryItemComponent {
  @Input('question') item:IQuestion;
  public conversation:IChat;
  public user:object;
  constructor( @Inject('paginateItemData') private data, private cd: ChangeDetectorRef) {
    this.item = data;
    this.conversation= this.item.activeConversation;
  }

  isShowUserArea():boolean{
    return (this.item && this.item.agent) ? true : false;
  }

  getRouterLink(){
    if(!this.item) return;
    if(this.conversation){
      return [
        '/client/dashboard/questions', this.item.id, 'chats', this.conversation.id
      ];
    }
    return [
      '/client/dashboard/questions', this.item.id, 'chats'
    ];
  }
}
