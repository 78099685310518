import { Action } from '@ngrx/store';
import { UserState } from "../reducers/user";

export const SET = '[UserState] SET';
export const UNSET = '[UserState] UNSET';

export class SetUserState implements Action {
    readonly type = SET;
    constructor(public payload: UserState) { }
}

export class UnsetUserState implements Action {
  readonly type = UNSET;
}

export type Action = SetUserState | UnsetUserState;
