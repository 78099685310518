import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-answer-price",
  templateUrl: "./answer-price.component.html",
  styleUrls: ["./answer-price.component.css"]
})
export class AnswerPriceComponent implements OnInit {
  private _addCreditsModal: NgbModalRef;
  @Output("closed") closed: EventEmitter<void> = new EventEmitter<void>();
  constructor(private _modalService: NgbModal) {}

  ngOnInit() {}

  openAddCreditsPopup(content) {
    this.closed.next();
    this._addCreditsModal = this._modalService.open(content, {
      ariaLabelledBy: "modal-basic-title",
      size: "lg"
    });
  }

  addCreditClosed(): void {
    this._addCreditsModal.close();
  }

  closePopup(): void {
    this.closed.next();
  }
}
