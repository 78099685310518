import { Component, forwardRef, Input } from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  NG_VALIDATORS,
  AbstractControl,
  ValidationErrors,
} from '@angular/forms';

@Component({
  selector: 'app-number-input',
  templateUrl: './number-input.component.html',
  styleUrls: ['./number-input.component.css'],
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => NumberInputComponent),
      multi: true,
    },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NumberInputComponent),
      multi: true,
    },
  ],
})
export class NumberInputComponent implements ControlValueAccessor {
  @Input('classes') classes: string;
  @Input('min') minVal: number = Number.MIN_SAFE_INTEGER;
  @Input('max') maxVal: number = Number.MAX_SAFE_INTEGER;
  @Input('step') step: number = 1;
  @Input() placeholder = '';

  // private precision:number = 1/this.step;
  private _selectValue: number = 0;
  private _onTouchedCallback: () => {};
  private _onChangeCallback: (_: any) => {};

  constructor() {}
  get precision() {
    return 1 / this.step;
  }

  writeValue(value: any) {
    this._selectValue = value;
  }

  get selectValue(): any {
    return this._selectValue;
  }

  set selectValue(value: any) {
    this.writeValue(value);
    if (this._onChangeCallback) {
      this._onChangeCallback(value);
    }

    if (this._onTouchedCallback) {
      this._onTouchedCallback();
    }
  }

  registerOnChange(fn: any) {
    this._onChangeCallback = fn || (() => {});
  }

  registerOnTouched(fn: any) {
    this._onTouchedCallback = fn || (() => {});
  }

  dec() {
    this.selectValue =
      (this.precision * this.selectValue - this.precision * this.step) /
      this.precision;
  }

  inc() {
    this.selectValue =
      (this.precision * this.selectValue + this.precision * this.step) /
      this.precision;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    if (this.selectValue >= this.minVal) {
      return null;
    }
    return {
      'min-error': {
        value: `value must be at least ${this.minVal}`,
      },
    };
  }
}
