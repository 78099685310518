import { Component, OnInit, Input, Output, EventEmitter, HostListener } from '@angular/core';

@Component({
  selector: 'tree-list-item',
  templateUrl: './item.component.html',
  styleUrls: ['./item.component.css']
})
export class ItemComponent implements OnInit {
  @Input('node') node;
  @Input('level') level;
  @Input('bindValue') bindValue:string = 'id';
  @Input('bindTitle') bindTitle:string = 'name';
  @Output('selected') selected:EventEmitter<any> = new EventEmitter<any>();
  constructor() { }

  ngOnInit() {
  }

  @HostListener("click") onClick() {
    this.selected.emit(this.node);
	}

}
