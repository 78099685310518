import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-sharing-buttons',
  templateUrl: './sharing-buttons.component.html',
  styleUrls: ['./sharing-buttons.component.css'],
})
export class SharingButtonsComponent implements OnInit {
  @Input() url: string;
  constructor() {}

  ngOnInit() {}
}
