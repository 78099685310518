import { Injectable } from "@angular/core";
import { PassportService } from "./passport.service";
import { HttpClient, HttpParams } from "@angular/common/http";
import { catchError, map } from "rxjs/operators";
import { Observable, of } from "rxjs";
import { API_ENDPOINT } from "../app.settings";

@Injectable({
  providedIn: "root"
})
export class CreditService {
  constructor(
    private _passportService: PassportService,
    private _httpClient: HttpClient
  ) {}

  addCreditByDefaultCard(options: object) {
    return this._httpClient.post(
      API_ENDPOINT + "/users/current/credits/stripe/store",
      options,
      this._passportService.httpOptions
    );
  }

  creditsStory(options: HttpParams) {
    return this._httpClient.get(API_ENDPOINT + "/users/current/credits", {
      ...this._passportService.httpOptions,
      params: options
    });
  }

  addCreditByPaypal(options: object) {
    return this._httpClient.post(API_ENDPOINT + "users/current/credits/paypal/store", 
      options,
      this._passportService.httpOptions
    );
  }

  // addCreditByStripeToken(token:string){
  //   return this._httpClient.post(API_ENDPOINT+"users/current/credits/stripe/store", { token }, this._passportService.httpOptions)
  // }
}
