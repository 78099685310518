import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter,
  AfterViewChecked,
  Renderer2,
} from '@angular/core';
import { MessageService } from '../../../../services/message.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ClientService } from '../../../../services/client.service';
import { IChat } from '../../../../models/chat/chat.model';
import {
  IQuestion,
  QuestionViewOption,
} from 'src/app/models/question/question.model';
import { ChatState } from 'src/app/store/reducers/chat.reducer';
import { SetChatState } from '../../../../store/actions/chat.action';
import { Store, select } from '@ngrx/store';
import { QuestionService } from 'src/app/services/question.service';
import { Subscription } from 'rxjs';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ISystemMessage } from 'src/app/models/chat/system-message.model';
import { ChatService } from 'src/app/services/chat.service';
import { IUser } from '../../../../models/user/user.model';
import { UserState } from '../../../../store/reducers/user';

@Component({
  selector: 'app-chat-area',
  templateUrl: './chat-area.component.html',
  styleUrls: ['./chat-area.component.css'],
})
export class ClientChatAreaComponent implements OnInit, AfterViewChecked {
  // must be array
  @ViewChild('content') addCreditElement;
  private _insufficientBalanceErrorModal: NgbModalRef;
  @ViewChild('insufficientBalanceErrorModalDOM')
  insufficientBalanceErrorModalDOM: ElementRef;
  public amountData: { amount: number; balance: number };
  public currentUser: IUser;

  public newSystemMessage: ISystemMessage = {
    text: `Your question has been sent.
    Once an agent agrees to answer it you’ll see their answer here.
    If it’s taking a while, try editing your offer to get it answered sooner!`,
    recepientID: null,
    id: null,
    isSystem: true,
  };

  public removedByAdminSystemMessage: ISystemMessage = {
    text: `Your question was removed by admin. Please contact support for detailed info.`,
    recepientID: null,
    id: null,
    isSystem: true,
  };

  // must be array
  public dragFiles: Array<File> = [];
  public chat: IChat;
  private _modal;
  public question: IQuestion;
  public viewOption: QuestionViewOption;
  public localViewOption = {
    showRejectForm: false,
  };
  public chatObservable: Subscription;

  @ViewChild('chatContent') chatContent: ElementRef;
  @Output('questionChanged') questionChanged = new EventEmitter();

  public messageForm: FormGroup = new FormGroup({
    message: new FormControl(null, Validators.required),
    'files[]': new FormControl(null),
  });

  public classesByStatus = {
    [QuestionService.STATUS_COMPLETED]: 'accept-answer-chat',
    [QuestionService.STATUS_ANSWER_REJECTED]: 'reject-answer-chat',
    [QuestionService.STATUS_REVIEW_COMPLETED]: 'accept-answer-chat',
  };

  constructor(
    private _messageService: MessageService,
    private _clientService: ClientService,
    private _store: Store<ChatState>,
    private _renderer: Renderer2,
    private _modalService: NgbModal,
    private _chatService: ChatService,
    private _userStore: Store<UserState>
  ) {
    this.chatObservable = _store
      .pipe(select('chat'))
      .subscribe((data: ChatState) => {
        this.question = data.question;
        this.viewOption = data.viewOption;
        this.chat = data.chat;
      });
    this._userStore.pipe(select('user')).subscribe((data: UserState) => {
      this.currentUser = data.activeUser;
    });
  }

  ngOnInit() {}

  ngAfterViewChecked() {
    this.chatContent.nativeElement.scrollTop = this.chatContent.nativeElement.scrollHeight;
    this.lastAnswerHandler(
      this.chatContent.nativeElement.querySelectorAll('.chat__item-responder')
    );
  }

  sendMessage(message) {
    message['files[]'] = this.dragFiles;
    this._messageService
      .sendMessage(this.chat.id, message)
      .subscribe((message) => {
        this.messageForm.reset();
        this.chat.messages.push(message);
        this.questionChanged.emit();
        this.dragFiles = [];
      });
  }

  rejectedHandler() {
    this.localViewOption.showRejectForm = true;
  }

  rejectedFormHandler() {
    this.localViewOption.showRejectForm = false;
  }

  filesHandler(event): void {
    this.messageForm.patchValue({ 'files[]': event.target.files });
  }

  accept(): void {
    this._clientService
      .acceptAnswer(this.question['id'])
      .subscribe((data: { question: IQuestion; meta: QuestionViewOption }) => {
        this._store.dispatch(
          new SetChatState({
            question: data.question,
            viewOption: data.meta,
          })
        );
      });
  }
  isNewQuestion() {
    return this.viewOption
      ? this.viewOption.status === QuestionService.STATUS_NEW
      : false;
  }

  isRemovedByAdminQuestion() {
    return this.viewOption
      ? this.viewOption.status === QuestionService.STATUS_REMOVED_BY_ADMIN
      : false;
  }

  isMessage(message): boolean {
    return !!message['body'];
  }

  isSystemMessage(message): boolean {
    return !!message['text'];
  }

  isShowMessageArea(): boolean {
    return this.viewOption ? !!this.viewOption.canSendMessage : false;
  }

  isShowMessageForm(): boolean {
    return this.viewOption
      ? !(this.viewOption.canAddReview || this.isShowRejectForm())
      : false;
  }

  isShowRejectAnswerButton() {
    return this.viewOption && this.viewOption.status
      ? [
          QuestionService.STATUS_CLIENT_REPLIED,
          QuestionService.STATUS_AGENT_REPLIED,
        ].includes(this.viewOption.status)
      : false;
  }

  isShowRejectForm(): boolean {
    return this.localViewOption ? this.localViewOption.showRejectForm : false;
  }

  isShowReviewArea() {
    return this.viewOption
      ? ([
          QuestionService.STATUS_REVIEW_COMPLETED,
          QuestionService.STATUS_COMPLETED,
          QuestionService.STATUS_ANSWER_REJECTED,
        ].includes(this.viewOption.status) ||
          !!this.viewOption.canAddReview) &&
          !this.localViewOption.showRejectForm
      : false;
  }

  isShowOfferExtraCreditsArea() {
    return this.viewOption ? this.viewOption.canOfferExtraCredits : false;
  }

  lastAnswerHandler(messagesContent: NodeList) {
    const lastAnswer: Node = messagesContent.item(messagesContent.length - 1);
    if (lastAnswer && this.viewOption) {
      this._renderer.addClass(
        lastAnswer,
        this.classesByStatus[this.viewOption.status]
      );
    }
  }

  openExtraCreditPopup(content) {
    this._modal = this._modalService.open(content, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'lg',
    });
  }
  balanceUpdated(): void {
    console.log(this.addCreditElement);
  }

  failedExtraCreditHandler(err): void {
    const { errors } = err;
    if (errors['amount']) {
      this.amountData = {
        amount: err['amount'],
        balance: this.currentUser.balance,
      };
      this._insufficientBalanceErrorModal = this._modalService.open(
        this.insufficientBalanceErrorModalDOM,
        {
          ariaLabelledBy: 'modal-basic-title',
        }
      );
    }
  }

  addedExtraCreditHandler() {
    if (this.chat) {
      this._chatService.getChat(this.chat.id).subscribe((chat: IChat) => {
        this._store.dispatch(
          new SetChatState({
            chat: chat,
          })
        );
      });
    }
    this._modal.close();
  }

  clientInsufficientBalancePopupClosed(): void {
    if (this._insufficientBalanceErrorModal) {
      this._insufficientBalanceErrorModal.close();
    }
  }
}
