import { Component, OnInit, Input } from '@angular/core';
import { IQuestion } from 'src/app/models/question/question.model';

@Component({
  selector: 'app-agent-review',
  templateUrl: './review.component.html',
  styleUrls: ['./review.component.css']
})
export class AgentReviewComponent implements OnInit {
  @Input('question') question:IQuestion;

  constructor() { }

  ngOnInit() {
  }

  calcResultIncome():number{
    return (this.question.transaction.amount - this.question.transaction.fee) || 0;
  }

}
