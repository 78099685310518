import { Component, OnInit, Input } from "@angular/core";
import { User } from "../../../models/user/user.model";
import {
  NgbModal,
  ModalDismissReasons,
  NgbActiveModal
} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-agent-mini-profile",
  templateUrl: "./mini-profile.component.html",
  styleUrls: ["./mini-profile.component.css"]
})
export class AgentMiniProfileComponent {
  @Input("user") user: User;
  private _activeAgentModal: NgbActiveModal;
  constructor(private _modalService: NgbModal) {}

  openAgentModal(content) {
    this._activeAgentModal = this._modalService.open(content, {
      ariaLabelledBy: "modal-basic-title"
    });
  }

  public lastOnline(){
    if(this.user.lastOnline){
      return `${this.user.lastOnline}h ago`;
    }
    return 'Online';
  }
}
