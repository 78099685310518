import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

@Component({
  selector: 'app-checkbox-agree',
  template: `
    <div class="authorization-form__agreement flex-wrapper left">
      <div class="checkbox">
        <input [(ngModel)] = "selectValue" class="checkbox__input" id="checkbox-agree" type="checkbox" checked>
        <label class="checkbox__label" for="checkbox-agree">
          <span></span>
          <p class="authorization-help-info">I agree to Time for Advice’s <a href="#">Terms of Use </a> and <a href="#"> Privacy Policy</a>.</p>
        </label>
      </div>
    </div>
  `,
  styles: [],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxAgreeComponent),
      multi: true,
    }
  ],
})
export class CheckboxAgreeComponent implements ControlValueAccessor {
  private _selectValue: boolean = false;
  private _onTouchedCallback: () => {};
  private _onChangeCallback: (_:any) => {};

  get selectValue(): any {
    return this._selectValue;
  }
  set selectValue(value: any) {
    this.writeValue(value);
    this._onChangeCallback(value);
    this._onTouchedCallback();
  }

  //From ControlValueAccessor interface
  writeValue(value: any) {
    this._selectValue = value;
  }

  //From ControlValueAccessor interface
  registerOnChange(fn: any) {
    this._onChangeCallback = fn;
  }

  //From ControlValueAccessor interface
  registerOnTouched(fn: any) {
    this._onTouchedCallback = fn;
  }
}
