import { Component, OnInit, Input } from '@angular/core';
import { IFile } from '../../../models/files/files.model';

@Component({
  selector: 'app-files-wrapper',
  templateUrl: './files-wrapper.component.html',
  styleUrls: ['./files-wrapper.component.css']
})
export class FilesWrapperComponent{
  @Input('files') files:Array<IFile> = [];

  constructor() { }
}
