export const API_ENDPOINT = 'https://tfa.dev.pinedev.eu/api';
export const ACTION_LOGOUT = 'logout';
export const WP_ENDPOINT = 'https://tfa-v2.pinedev.in.ua';
export const STRIPE_API_KEY = 'pk_test_fC74XP3RXFIyHjJfKsfA5tUi00tIHhFw9F';
export const STRIPE_CURRENCY = 'AUD';
export const CLIENT_PREMIUM_CONTENT = [{
  iconFile: 'assets/images/premium-badge_wh.svg',
  link: 'https://www.timeforadvice.com/premium-content/',
  label: 'Premium Content'
}];

export const PAYPAL_API_SETTING = Object.freeze({
  mod: 'sandbox',
});
