import { Component, OnInit, Input } from '@angular/core';
import { IQuestion } from "../../../../models/question/question.model";

@Component({
  selector: 'app-agent-chat-question',
  templateUrl: './chat-question.component.html',
  styleUrls: ['./chat-question.component.css']
})
export class AgentChatQuestionComponent {
  @Input('question') question:IQuestion ;
  constructor() { }
}
