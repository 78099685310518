import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { IQuestion, QuestionViewOption } from 'src/app/models/question/question.model';
import { SetChatState } from 'src/app/store/actions/chat.action';
import { RejectMessageService } from 'src/app/services/reject-message.service';
import { Store, select } from '@ngrx/store';
import { ChatState } from 'src/app/store/reducers/chat.reducer';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-client-reject-form',
  templateUrl: './reject-form.component.html',
  styleUrls: ['./reject-form.component.css']
})
export class ClientRejectFormComponent implements OnInit {
  public rejectForm: FormGroup;
  public chatObservable:Subscription;
  public question:IQuestion;
  public quectionViewOption: QuestionViewOption;
  @Output('rejected') rejectEvent:EventEmitter<void> = new EventEmitter<void>();


  constructor(
    private _fb: FormBuilder,
    private _rejectMessageService:RejectMessageService,
    private _store:Store<ChatState>,
  ) {

    this.chatObservable =  _store.pipe(select('chat')).subscribe((state:ChatState)=>{
      this.question = state.question;
      this.quectionViewOption = state.viewOption;
    });
  }

  ngOnInit() {
    this.initRejectForm();
  }

  rejectAnswer(values):void{
    this._rejectMessageService.store(values).subscribe(
      (data:{question:IQuestion, meta:QuestionViewOption})=>{
        this._store.dispatch(new SetChatState({
          question:data.question,
          viewOption:data.meta
        }));
        this.rejectEvent.emit();
      }
    )
  }

  initRejectForm():void{
    this.rejectForm = this._fb.group({
      message:['', Validators.required],
      question_id:[this.question.id, Validators.required],
    });
  }

}
