import { Injectable } from '@angular/core';
import { PassportService } from './passport.service';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { API_ENDPOINT } from "../app.settings";


@Injectable({
  providedIn: 'root'
})

export class CountryRegionService {

  constructor(
    private _passportService:PassportService,
    private _httpClient:HttpClient  
  ) {}

  countryObservable():Observable<any[]>{
     return this._httpClient.get(API_ENDPOINT+'/countries',this._passportService.httpOptions)
     .pipe(map((countries:any[])=>countries)); 
  }
}
