import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs';
import { IQuestion, QuestionViewOption } from 'src/app/models/question/question.model';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AgentService } from 'src/app/services/agent.service';
import { Store, select } from '@ngrx/store';
import { ChatState } from 'src/app/store/reducers/chat.reducer';
import { HttpParams } from '@angular/common/http';
import { SetChatState } from 'src/app/store/actions/chat.action';

@Component({
  selector: 'app-request-extra-credit',
  templateUrl: './request-extra-credit.component.html',
  styleUrls: ['./request-extra-credit.component.css']
})
export class RequestExtraCreditComponent implements OnInit {
  public requestCreditForm: FormGroup;
  public question: IQuestion;
  public chatObservable: Subscription;
  @Output() added:EventEmitter<void> = new EventEmitter();
  
  constructor(
    private _fb:FormBuilder,
    private _agentService:AgentService,
    private _store: Store<ChatState>,

  ) { 
    this.requestCreditForm = this.initCreditForm(this._fb);
  }

  ngOnInit() {
    this.chatObservable = this._store
    .pipe(select("chat"))
    .subscribe((data: ChatState) => {
      this.question = data.question;
    });
  }

  initCreditForm(fb: FormBuilder): FormGroup {
    return fb.group({
      amount: [0, [Validators.required, Validators.min(1)]]
    });
  }

  requestCreditSubmit(value){
    const params = new HttpParams({ fromObject: value });
    this._agentService.requestExtraCredit(this.question.id, params).subscribe(
      (data:{question:IQuestion, meta:QuestionViewOption})=>{
        this._store.dispatch(new SetChatState({
          question:data.question,
          viewOption:data.meta
        }));
        this.added.emit();
      }
    );
  }



}
