import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';

import { UserService } from '../services/user.service'

@Injectable({
  providedIn: 'root'
})
export class AuthConfirmGuard implements CanActivate {
  constructor (
    private _router:Router,
    private _userService:UserService
  ){

  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const token:string = next.queryParams.token;
    if(!token){
      this._router.navigate(['/']);
      return false;
    }
    return this._userService.confirmUser(token);
  }
}
