import { Action } from '@ngrx/store';
import { UiState } from "../reducers/ui.reducer";

export const SET = '[UiState] Set';
export const UNSET = '[UiState] UNSET';

export class SetUiState implements Action {
    readonly type = SET;
    constructor(public payload: UiState) { }
}

export class UnsetUiState implements Action {
  readonly type = UNSET;
  // constructor(public payload: ChatState) { }
}

export type Action = SetUiState | UnsetUiState;
