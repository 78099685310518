import {
  Component,
  OnInit,
  HostListener,
  Renderer2,
  ElementRef,
  ViewChild,
  AfterViewInit
} from "@angular/core";
import { WP_ENDPOINT } from "../app.settings";
import { WpService } from "../services/wp.service";

@Component({
  selector: "auth-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.css"]
})
export class AuthHeaderComponent implements OnInit, AfterViewInit {
  public wp: string = WP_ENDPOINT;
  @ViewChild("menu") menu: ElementRef;
  @ViewChild("mobileHeader") mobileHeader: ElementRef;
  @ViewChild("header") header: ElementRef;
  public menuItems: Array<object>;

  constructor(private renderer: Renderer2, private _wpService: WpService) {}

  ngAfterViewInit() {
    window.dispatchEvent(new Event("resize"));
  }

  ngOnInit() {
    this._wpService.getMenu().subscribe(data => {
      this.menuItems = data["items"];
      console.log(this.menuItems);
    });
  }

  close() {
    this.renderer.addClass(this.mobileHeader.nativeElement, "hidden");
  }

  open() {
    this.renderer.removeClass(this.mobileHeader.nativeElement, "hidden");
  }

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    if (event.target.innerWidth < 992) {
      if (
        !(<Node>this.mobileHeader.nativeElement).contains(<Node>(
          this.menu.nativeElement
        ))
      ) {
        this.renderer.appendChild(
          this.mobileHeader.nativeElement,
          this.menu.nativeElement
        );
      }
    } else {
      if (
        !(<Node>this.header.nativeElement).contains(<Node>(
          this.menu.nativeElement
        ))
      ) {
        this.renderer.appendChild(
          this.header.nativeElement,
          this.menu.nativeElement
        );
      }
    }
  }
}
