import {
  Component,
  forwardRef,
  HostListener,
  OnInit,
  ElementRef,
  Input,
  EventEmitter,
  Output,
  OnChanges,
  SimpleChange,
  SimpleChanges
} from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

import { isArray } from "util";
import { select } from "@ngrx/store";

@Component({
  selector: "app-tree-select",
  template: `
    <app-tree-selected
      [ngClass]="{ open: !isHidden }"
      [placeholder]="placeholder"
      [isOpen]="!isHidden"
      (click)="onSelectedHandler()"
      [(value)]="title"
    ></app-tree-selected>
    <div [hidden]="isHidden" id="list">
      <div [ngClass]="'list-wrapper'">
        <app-tree-list
          [nodes]="nodes"
          (selected)="selectedHandler($event)"
          [bindValue]="bindValue"
          [bindTitle]="bindTitle"
          [bindChild]="bindChild"
        ></app-tree-list>
      </div>
    </div>
  `,
  styles: [
    `
      #list {
        background-color: #fff;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        box-sizing: border-box;
        position: absolute;
        width: 100%;
        z-index: 1050;
        box-shadow: 0px 5px 8px 0 rgba(0, 0, 0, 0.25);
        border-right: 0;
        border-left: 0;
        border-top: 0;
        top: 32px;
        padding-bottom: 5px;
      }

      .list-wrapper {
        margin-bottom: 5px;
        overflow-y: auto;
        height: auto;
        width: 100%;
        box-sizing: border-box;
        max-height: 240px;
      }
      :host {
        display: flex;
        position: relative;
        width: 100%;

        left: 0;
        right: 0;
      }
    `
  ],
  //  [filter]="title"

  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TreeSelectComponent),
      multi: true
    }
  ]
})
export class TreeSelectComponent
  implements ControlValueAccessor, OnInit, OnChanges {
  private onChange: Function;
  private onTouched: Function;
  private _value: any;
  private _title: string;
  private _hidden: boolean = true;
  @Input("nodes") nodes: Array<object>;
  @Input("level") level: number = 0;
  @Input("bindValue") bindValue: string = "id";
  @Input("bindTitle") bindTitle: string = "name";
  @Input("bindChild") bindChild: string = "child";
  @Input() placeholder = "Category";
  // @Output('changed') changed:EventEmitter<object> = new EventEmitter<object>();

  constructor(private eRef: ElementRef) {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    const nodes: SimpleChange = changes.nodes;

    nodes.currentValue instanceof Array &&
      this.update(this.findById(nodes.currentValue, this.value));
  }

  findById(data, id) {
    for (var i = 0; i < data.length; i++) {
      if (data[i][this.bindValue] == id) {
        return data[i];
      } else if (
        data[i][this.bindChild] &&
        data[i][this.bindChild].length &&
        typeof data[i][this.bindChild] === "object"
      ) {
        return this.findById(data[i][this.bindChild], id);
      }
    }
  }

  get isHidden(): boolean {
    return this._hidden;
  }

  get value() {
    return this._value;
  }

  set value(value: any) {
    this._value = value;
    this.onChange && this.onChange(value);
    value === null && (this.title = null);
  }

  get title() {
    return this._title;
  }

  set title(val: string) {
    this._title = val;
  }

  onSelectedHandler() {
    this._hidden = !this._hidden;
  }

  selectedHandler(value): void {
    this.update(value);
    this.onSelectedHandler();
  }

  update(value):void{
    if(!value) return;
    this.title = value[this.bindTitle] || "";
    this.value = value[this.bindValue] || "";
  }

  writeValue(value: object): void {
    this.value = value;
  }

  registerOnChange(fn: Function): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: Function): void {
    this.onTouched = fn;
  }

  @HostListener("document:click", ["$event"])
  clickout(event) {
    if (!this.eRef.nativeElement.contains(event.target)) {
      this._hidden = true;
    }
  }
}
