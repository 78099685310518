import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

//guards
import { AuthConfirmGuard } from './guards/auth-confirm.guard';
import { BetaModeGuard } from './guards/beta-mode.guard';
import { AuthGuardGuard } from './guards/auth-guard.guard';
import { IsAgentGuard } from './guards/is-agent.guard';
import { IsClientGuard } from './guards/is-client.guard';

//components
import { NotFoundComponent } from './components/not-found/not-found.component';
import { ClientChatComponent } from './client-components/dashboard/chat/chat.component';

//Client
import { ClientLoginComponent } from './client-components/auth/login/login.component';
import { ClientSignupComponent } from './client-components/auth/signup/client.component';
import { CheckEmailClientComponent } from './client-components/auth/check-email/client.component';
import { AuthConfirmedComponent } from './client-components/auth/auth-confirmed/auth-confirmed.component';
import { ClientDashbourdComponent } from './client-components/dashboard/dashbourd.component';
import { ClientProfileComponent } from './client-components/dashboard/profile/profile.component';
import { ClientQuestionsComponent } from './client-components/dashboard/questions/questions.component';
import { ClientWalletComponent } from './client-components/dashboard/wallet/wallet.component';
import { ClientInboxQuestionsComponent } from './client-components/dashboard/inbox-questions/inbox-questions.component';
import { ClientForgetPassComponent } from './client-components/auth/forget-pass/forget-pass.component';
import { ClientChangePasswordComponent } from './client-components/dashboard/change-password/change-password.component';

//Agent
import { AgentSignupComponent } from './agent-components/auth/signup/signup.component';
import { AgentLoginComponent } from './agent-components/auth/login/login.component';
import { AgentQuestionsComponent } from './agent-components/dashboard/questions/questions.component';
import { AgentDashboardComponent } from './agent-components/dashboard/dashboard.component';
import { AgentAttachedQuestionsComponent } from './agent-components/dashboard/attached-questions/attached-questions.component';
import { AgentChatComponent } from './agent-components/dashboard/chat/chat.component';
import { AgentWalletComponent } from './agent-components/dashboard/wallet/wallet.component';
import { AgentProfileComponent } from './agent-components/dashboard/profile/profile.component';
import { AgentForgetPassComponent } from './agent-components/auth/forget-pass/forget-pass.component';
import { ResetPasswordComponent } from './general/auth/reset-password/reset-password.component';
import { AgentChangePasswordComponent } from './agent-components/dashboard/change-password/change-password.component';

const routes: Routes = [
  { path: '', redirectTo: 'client/login', pathMatch: 'full' },
  {
    path: 'signup/confirm/email',
    pathMatch: 'full',
    component: AuthConfirmedComponent,
    canActivate: [AuthConfirmGuard],
  },
  {
    path: 'password/reset',
    pathMatch: 'full',
    component: ResetPasswordComponent,
  },

  { path: 'beta-mode', component: AuthConfirmedComponent },

  { path: 'agent/login', component: AgentLoginComponent },
  { path: 'agent/signup', component: AgentSignupComponent },
  { path: 'agent/check-email', component: CheckEmailClientComponent },
  { path: 'agent/forget-pass', component: AgentForgetPassComponent },

  { path: 'client/login', component: ClientLoginComponent },
  { path: 'client/signup', component: ClientSignupComponent },
  { path: 'client/check-email', component: CheckEmailClientComponent },
  { path: 'client/forget-pass', component: ClientForgetPassComponent },
  {
    path: 'client/dashboard',
    component: ClientDashbourdComponent,
    canActivate: [IsClientGuard, BetaModeGuard],
    children: [
      { path: 'profile', component: ClientProfileComponent },
      { path: 'questions', component: ClientQuestionsComponent },
      {
        path: 'questions/:questionID/chats/:chatID',
        component: ClientChatComponent,
      },
      {
        path: 'change-password',
        component: ClientChangePasswordComponent,
      },
      { path: 'questions/:questionID/chats', component: ClientChatComponent },
      { path: 'wallet', component: ClientWalletComponent },
      { path: 'inbox', component: ClientInboxQuestionsComponent },
      { path: '', redirectTo: 'questions', pathMatch: 'full' },
    ],
  },
  {
    path: 'agent/dashboard',
    component: AgentDashboardComponent,
    canActivate: [IsAgentGuard, BetaModeGuard],
    children: [
      {
        path: 'questions',
        component: AgentAttachedQuestionsComponent,
        data: { title: 'questions' },
      },
      {
        path: 'question-pool',
        component: AgentQuestionsComponent,
        data: { title: 'question-pool' },
      },
      {
        path: 'questions/:questionID/chats/:chatID',
        component: AgentChatComponent,
      },
      { path: 'wallet', component: AgentWalletComponent },
      { path: 'profile', component: AgentProfileComponent },
      {
        path: 'change-password',
        component: AgentChangePasswordComponent,
      },

      // {path: 'inbox', component: ClientInboxQuestionsComponent},
      { path: '', redirectTo: 'question-pool', pathMatch: 'full' },
    ],
  },

  { path: '**', component: NotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
