import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import { CategoryService } from "src/app/services/category.service";
import { StatusService } from "src/app/services/status.service";
import { FormBuilder, FormGroup } from "@angular/forms";

@Component({
  selector: "app-question-filter",
  templateUrl: "./question-filter.component.html",
  styleUrls: ["./question-filter.component.css"]
})
export class QuestionFilterComponent implements OnInit {
  private _treeValues: Array<object>;
  private _statuses: Array<object>;
  public filterForm:FormGroup;
  @Output('changed') changed: EventEmitter<Object> = new EventEmitter<Object>();

  constructor(
    private _categoryService: CategoryService,
    private _statusService: StatusService,
    private _fb: FormBuilder
  ) {}

  ngOnInit() {
    this.filterForm = this.initFilterForm(this._fb);
    this.initCategories();
    this.initQuestionStatuses();
  }

  timeFilter() {
    return [
      { id: "0", name: "All Time" },
      { id: "7", name: "Last 7 days" },
      { id: "14", name: "Last 14 days" },
      { id: "30", name: "Last 30 days" }
    ];
  }

  get treeValues() {
    return this._treeValues;
  }

  get statuses(){
    return this._statuses;
  }

  initCategories() {
    this._categoryService
      .categoriesTreeObservable({
        id: "id",
        title: "name",
        recursive_tree: "child"
      })
      .subscribe((tree: Array<object>) => {
        this._treeValues = tree;
      });
  }

  initQuestionStatuses() {
    this._statusService.question().subscribe(data => {
      this._statuses = data;
    });
  }

  initFilterForm(fb:FormBuilder):FormGroup{
    return fb.group({
      time:[null],
      category:[null],
      status:[null],
    })
  }

  changeForm(){
    this.changed.emit(this.filterForm.value);
  }
  
}
