import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class FileService {
  constructor() {}

  public toFormData(data: object) {
    const formData = new FormData();
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        if (data[key] instanceof Array) {
          for (const item of data[key]) {
            if (item instanceof File) {
              formData.append(key, item);
            }
          }
          continue;
        }
        formData.append(key, data[key]);
      }
    }
    return formData;
  }
}
