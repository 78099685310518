import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "app-file-input",
  templateUrl: "./file-input.component.html",
  styleUrls: ["./file-input.component.css"]
})
export class FileInputComponent implements OnInit {
  @Input("files") dragFiles: Array<File> = [];
  @Output() filesChange: EventEmitter<Array<File>> = new EventEmitter<
    Array<File>
  >();
  constructor() { }

  ngOnInit() { }

  fileUploaded(files: File[]): void {
    if (!files) return;
    this.dragFiles.push(...files);
    this.filesChange.emit(this.dragFiles);
  }

  deleteDropFile(index: number) {
    this.dragFiles.splice(index, 1);
    this.filesChange.emit(this.dragFiles);
  }
}
