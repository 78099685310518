import { Component, OnInit, Input, Inject } from '@angular/core';
import { IChat, Chat } from "../../../../models/chat/chat.model";
import { Message } from "../../../../models/chat/message.model";

@Component({
  selector: 'app-attached-question-item',
  templateUrl: './item.component.html',
  styleUrls: ['./item.component.css']
})
export class AgentAttachedQuestionItemComponent {
  @Input('question') item;
  public conversation:IChat;
  public user:object;
  constructor( @Inject('paginateItemData') private data ) {
    this.item = data;
    [ this.conversation ] = this.item.active_conversations;
  }

  getRouterLink(){
    if(this.conversation){
      return [
        '/agent/dashboard/questions', this.item.id, 'chats', this.conversation.id
      ];
    }
  }
}
