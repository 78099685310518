import { Component, OnInit, Input } from "@angular/core";
import {
  IQuestion,
  QuestionViewOption
} from "../../../../models/question/question.model";
import { Subscription } from "rxjs";
import { Store, select } from "@ngrx/store";
import { ChatState } from "src/app/store/reducers/chat.reducer";
import { QuestionService } from "src/app/services/question.service";
import { inArray } from "src/app/helpers/array";
import { ClientService } from "src/app/services/client.service";
import { Router } from "@angular/router";
import { AuthService } from "src/app/services/auth.service";

@Component({
  selector: "app-chat-question",
  templateUrl: "./chat-question.component.html",
  styleUrls: ["./chat-question.component.css"]
})
export class ClientChatQuestionComponent implements OnInit {
  @Input("question") question: IQuestion;
  public viewOption: QuestionViewOption;
  public chatObservable: Subscription;
  constructor(
    private _store: Store<ChatState>,
    private _clientService: ClientService,
    private _authService :AuthService,
    private _router: Router
  ) {
    this.chatObservable = _store
      .pipe(select("chat"))
      .subscribe((data: ChatState) => {
        this.viewOption = data.viewOption;
      });
  }

  isShowCancelButton(): boolean {
    return this.viewOption
      ? inArray(
          [QuestionService.STATUS_AGENT_ASSIGNED, QuestionService.STATUS_NEW],
          this.viewOption.status
        )
      : false;
  }

  cancelQuestion() {
    this._clientService.cancelAnswer(this.question.id).subscribe( () => {
      this._authService.currentUserObservable();
      this._router.navigate(['/client/dashboard/questions']);
    })
  }

  ngOnInit() {}
}
