import { Component, OnInit, Input, forwardRef } from '@angular/core';
import { AgentService } from '../../../services/agent.service';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { IUser } from '../../../models/user/user.model';
import { ToastrService } from 'ngx-toastr';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-apply-agent-code',
  templateUrl: './apply-agent-code.component.html',
  styleUrls: ['./apply-agent-code.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ApplyAgentCodeComponent),
      multi: true,
    },
  ],
})
export class ApplyAgentCodeComponent implements OnInit, ControlValueAccessor {
  public faTimes = faTimes;
  @Input()
  set value(val) {
    if (!val) {
      this._agent = null;
    }
    this._value = val;
    this.onChange(this._value);
  }
  private _value = '';
  private _agent: IUser = null;

  constructor(
    private _agentService: AgentService,
    private _toastrService: ToastrService
  ) {}

  ngOnInit() {}

  get value() {
    return this._value;
  }

  onChange(_: any): void {}

  setEmptyState(): void {
    this._agent = null;
    this.value = '';
  }

  clearAgent(): void {
    this.setEmptyState();
  }

  applyAgentCode(): void {
    const code = this.value;
    this._agentService.getByCode(code).subscribe(
      (responseAgent) => {
        this._agent = responseAgent;
        // this.value = code;
      },
      (error) => {
        this.setEmptyState();
        if (error.status === 404) {
          this._toastrService.error('Agent not found');
        }
      }
    );
  }

  hasAgent(): boolean {
    return this._agent !== null;
  }

  showAgentName(): string {
    if (this._agent) {
      return this._agent.fullName;
    }
    return '';
  }

  agentAvatar(): string {
    if (this._agent) {
      return this._agent.avatar;
    }
    return '';
  }

  isAddCodeDisabled(): boolean {
    return !this.value;
  }

  writeValue(value: any): void {
    this.value = value;
  }

  registerOnChange(fn): void {
    this.onChange = fn;
  }

  registerOnTouched(): void {}
}
