import { Component, OnInit, Input } from '@angular/core';
import { User } from "../../../models/user/user.model";

@Component({
  selector: 'app-client-mini-profile',
  templateUrl: './mini-profile.component.html',
  styleUrls: ['./mini-profile.component.css']
})
export class ClientMiniProfileComponent{
  @Input('user') user:User;
  constructor() { }

  public lastOnline(){
    if(this.user && this.user.lastOnline){
      return `${this.user.lastOnline}h ago`;
    }
    return 'Online';
  }

}
