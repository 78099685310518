import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'firstToUpper'
})
export class FirstToUpperPipe implements PipeTransform {

  transform(value: string, args?: string): string {
    if(!value) return '';
    if(!args){
      return this.toUpperCase(value);
    }

    const words = value.split(args);
    return words.reduce((scope, item) => {
      return scope+args+this.toUpperCase(item);
    }, "");
  }

  toUpperCase(value:string){
    return value.charAt(0).toUpperCase() + value.slice(1);
  }

}
