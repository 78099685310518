import { Component, OnInit } from "@angular/core";
import { HttpClient, HttpParams, HttpHeaders } from "@angular/common/http";
import { AgentAttachedQuestionItemComponent } from "./item/item.component";
import { AgentService } from "../../../services/agent.service";
import { Subject } from "rxjs";

@Component({
  selector: "app-agent-attached-questions",
  templateUrl: "./attached-questions.component.html",
  styleUrls: ["./attached-questions.component.css"],
  entryComponents: [AgentAttachedQuestionItemComponent]
})
export class AgentAttachedQuestionsComponent {
  public questionsSubject: Subject<void> = new Subject<void>();
  public filter: Object = new Object();
  constructor(public agentService: AgentService) {}

  changedFilter(event) {
    this.filter = event;
    this.questionsSubject.next();
  }

  questions(params: HttpParams) {
    params = this.toQuery(params, this.filter);
    return this.agentService.questions(params);
  }

  toQuery(params: HttpParams, obj): HttpParams {
    Object.keys(obj).map(function(key) {
      const value = obj[key];
      if (value) {
        params = params.append(key, value);
      }
    });
    return params;
  }

  paginateComponentRef() {
    return AgentAttachedQuestionItemComponent;
  }
}
