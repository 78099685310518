import { Component, OnInit } from '@angular/core';
import {
  Validators,
  FormGroup,
  FormControl,
  AbstractControl,
} from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { filter } from 'rxjs/operators';

import { AuthService } from '../../../services/auth.service';
import { AppService } from '../../../services/app.service';
import { Store } from '@ngrx/store';
import { appReducerState } from '../../../store/reducers/appReducer';
import { ACTION_LOGIN, ACTION_LOGOUT } from '../../../store/actions/appActions';

@Component({
  selector: 'auth-client-signup',
  templateUrl: './client.component.html',
  styleUrls: ['./client.component.css'],
})
export class ClientSignupComponent implements OnInit {
  public signupClientForm: FormGroup = new FormGroup({
    first_name: new FormControl(null, Validators.required),
    last_name: new FormControl(null, Validators.required),
    email: new FormControl(
      null,
      Validators.compose([Validators.required, Validators.email])
    ),
    password: new FormGroup(
      {
        password: new FormControl(null, Validators.required),
        password_confirmation: new FormControl(null, Validators.required),
      },
      this.passwordConfirming
    ),
    country_of_residence_code: new FormControl(null, Validators.required),
    is_agree_terms_and_policy: new FormControl(false, Validators.requiredTrue),
  });

  constructor(
    private _authService: AuthService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _appService: AppService,
    private _store: Store<appReducerState>
  ) {}

  ngOnInit() {
    this._route.queryParams
      .pipe(filter(({ question }) => question))
      .subscribe(({ question }) => {
        this.signupClientForm.addControl('question', new FormControl(question));
      });
  }

  getState() {
    return this._store.select('appReducer');
  }

  onSignupClient({ password: { password, password_confirmation }, ...next }) {
    const signupObservable = this._authService.signupObservable(
      { ...next, password, password_confirmation },
      'client'
    );

    signupObservable.subscribe(
      (response) => {
        this._router.navigate(['client/check-email']);
      },
      (errorResponse) => {
        const errors: Array<string> = this._appService.getError(errorResponse);
        this._appService.setFormGroupErrors(this.signupClientForm, errors);
      }
    );
  }

  passwordConfirming(c: AbstractControl): { invalid: boolean } {
    if (c.get('password').value !== c.get('password_confirmation').value) {
      return { invalid: true };
    }
  }
}
