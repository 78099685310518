import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';

@Component({
  selector: 'app-sub-header',
  templateUrl: './sub-header.component.html',
  styleUrls: ['./sub-header.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class SubHeaderComponent implements OnInit {
  @Input() items:Array<object>;
  constructor() { }

  ngOnInit() {
  }

}
