import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-dashboard-sub-header',
  templateUrl: './dashboard-sub-header.component.html',
  styleUrls: ['./dashboard-sub-header.component.css']
})
export class ClientDashboardSubHeaderComponent implements OnInit {

  @Input('title') title:string;

  constructor() { }

  ngOnInit() {
  }

}
