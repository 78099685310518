import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-client-dasboard-page",
  templateUrl: "./client-dasboard-page.component.html",
  styleUrls: ["./client-dasboard-page.component.css"]
})
export class ClientDasboardPageComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
