import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'toDateStamp'
})
export class ToDateStampPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (!value) return +(new Date());
    return +(new Date(value.replace(/-/g,"/")));
  }

}
