import { Component, OnDestroy } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { UiState } from 'src/app/store/reducers/ui.reducer';
import { Subscription } from 'rxjs';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-dashbourd',
  templateUrl: './dashbourd.component.html',
  styleUrls: ['./dashbourd.component.css'],
})
export class ClientDashbourdComponent implements OnDestroy {
  public currentPageTitle: string;
  public titleObserver: Subscription;
  constructor(
    private _store: Store<UiState>,
    private _authService: AuthService
  ) {
    this._authService.currentUserObservable();
    this.titleObserver = _store
      .pipe(select('ui'))
      .subscribe((state: UiState) => {
        this.currentPageTitle = state.subTitle;
      });
  }

  ngOnDestroy() {
    //need array
    this.titleObserver.unsubscribe();
  }
}
