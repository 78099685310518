import { Injectable, Injector } from '@angular/core';
import { API_ENDPOINT } from "../app.settings";
import { PassportService } from "./passport.service";
import { map, catchError } from 'rxjs/operators';
import { HttpClient, HttpParams } from '@angular/common/http';
import { IChat } from '../models/chat/chat.model';
import { IMessage } from "../models/chat/message.model";
import { SystemMessageService } from './system-message.service';



@Injectable({
  providedIn: 'root'
})
export class ChatService {

  constructor(
    private _passportService:PassportService,
    private _httpClient:HttpClient,
    private _inj:Injector,
  ) { }

  getChat(chatID:number, params?:HttpParams){
    return this._httpClient.get(`${API_ENDPOINT}/conversations/${chatID}`,
      {
        ...this._passportService.httpOptions,
        params:params
      }
    ).pipe(
      map((response)=>{
        return response['data'];
      })
    ).pipe(
      map((chat: IChat) => {
        chat.messages = chat.messages.concat(chat.systemMessages);
        chat.messages = chat.messages.sort((a, b) => {
          return (new Date(a.created)).getTime() - (new Date(b.created)).getTime();
        });
        return chat;
      })
    )
  }
}
