import { Injectable } from '@angular/core';
import { PassportService } from "./passport.service";
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { Observable, of } from "rxjs";
import { API_ENDPOINT } from "../app.settings";
import { IReview } from "../models/review/review.model";

@Injectable({
  providedIn: 'root'
})
export class ReviewService {

  constructor(
    private _passportService:PassportService,
    private _httpService:HttpClient
  ) { }

  public store(data):Observable<IReview>{
    return this._httpService.post(API_ENDPOINT+"/reviews", data, this._passportService.httpOptions).pipe(map(
      (data)=>{
        return <IReview>data['data'];
      }
    ));
  }

  public showByUser(options?:HttpParams):Observable<{reviews:Array<IReview>, meta:any}>{
    return this._httpService.get(API_ENDPOINT+"/reviews", {
      ...this._passportService.httpOptions,
      params:options
    }).pipe(map(
      (data)=>{
        return {
          reviews:<Array<IReview>>data['data'],
          meta:data['meta']
        };
      }
    ));
  }


}
