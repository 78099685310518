import { Directive, Input, Output, EventEmitter, Renderer2, HostListener } from '@angular/core';

@Directive({
  selector: '[appFileUpload]'
})
export class FileUploadDirective {
  @Input() key: string;
  @Output() uploaded: EventEmitter<File[] | File> = new EventEmitter<File[] | File>();
  private _input: Node;
  private _multiple: boolean = false;
  @Input()
  set multiple(val: boolean) {
    this._multiple = val;
    if (this.multiple) {
      this._renderer.setAttribute(this._input, 'multiple', '');
    }
  }

  constructor(
    private _renderer: Renderer2
  ) {
    this._input = this._renderer.createElement('input');
    this._renderer.setAttribute(this._input, 'type', 'file');
    this._input.addEventListener('change', (event: Event) => {
      const files: FileList = (<HTMLInputElement>event.target).files;
      if (this.multiple) {
        files && this.uploaded.emit(Array.from(files));
      } else {
        files && this.uploaded.emit(files.item(0));
      }
    })
  }

  get multiple(): boolean {
    return this._multiple;
  }
  @HostListener('click') click() {
    this._input.dispatchEvent(new MouseEvent('click'));
  }

}
