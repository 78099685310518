import { Action } from '@ngrx/store';
import { ChatState } from "../reducers/chat.reducer";

export const SET = '[ChatState] Set';
export const UNSET = '[ChatState] UNSET';

export class SetChatState implements Action {
    readonly type = SET;
    constructor(public payload: ChatState) { }
}

export class UnsetChatState implements Action {
  readonly type = UNSET;
  // constructor(public payload: ChatState) { }
}

export type Action = SetChatState | UnsetChatState;
