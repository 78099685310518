import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { WP_ENDPOINT } from "../app.settings";

@Injectable({
  providedIn: 'root'
})
export class WpService {
  private wpApiUrl = `${WP_ENDPOINT}/wp-json`;
  constructor(
    private _httpClient:HttpClient,
  ) { }

  getMenu(){
    return this._httpClient.get(`${this.wpApiUrl}/wp-api-menus/v2/menus/2`);
  }
}
