import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../services/auth.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import {
  AbstractControl,
  FormGroup,
  FormControl,
  Validators,
} from '@angular/forms';
@Component({
  selector: 'app-agent-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css'],
})
export class AgentChangePasswordComponent implements OnInit {
  public passwordForm: FormGroup;
  constructor(
    private _authService: AuthService,
    private _toastrService: ToastrService,
    private _router: Router
  ) {
    this.passwordForm = this.initForm();
  }

  ngOnInit() {}

  passwordConfirming(c: AbstractControl): { invalid: boolean } {
    if (c.get('new_password').value !== c.get('new_confirm_password').value) {
      return { invalid: true };
    }
  }

  initForm(): FormGroup {
    return new FormGroup({
      current_password: new FormControl(null, Validators.required),
      passwords: new FormGroup(
        {
          new_password: new FormControl(null, Validators.required),
          new_confirm_password: new FormControl(null, Validators.required),
        },
        this.passwordConfirming
      ),
    });
  }

  onChangePassword(formData): void {
    const {
      current_password,
      passwords: { new_password, new_confirm_password },
    } = formData;
    this._authService
      .changePassword({
        current_password,
        new_password,
        new_confirm_password,
      })
      .subscribe(() => {
        this._toastrService.success('Password changed');
        this._router.navigate(['/client/dashboard/profile']);
      });
  }
}
