import { Component, OnInit } from '@angular/core';
import { CurrentUser, IUser } from "../../../models/user/user.model";
import { Store, select } from '@ngrx/store';
import { UserState } from 'src/app/store/reducers/user';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-agent-site-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.css']
})
export class AgentSideBarComponent implements OnInit {
  public currentUser:IUser;
  constructor(
    private _userStore: Store<UserState>,
    private _authService:AuthService
  ) {}

  ngOnInit(){
    this._userStore.pipe(select('user')).subscribe((data:UserState)=>{
      this.currentUser = data.activeUser;
    });
  }

  logout():void{
    this._authService.logout('agent');
  }

}
