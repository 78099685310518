import { Component, OnInit } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  AbstractControl,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';

import { AuthService } from '../../../services/auth.service';
import { AppService } from '../../../services/app.service';

@Component({
  selector: 'app-agent-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css'],
})
export class AgentSignupComponent implements OnInit {
  public signupAgentForm: FormGroup;

  constructor(
    private _fb: FormBuilder,
    private _authService: AuthService,
    private _router: Router,
    private _appService: AppService
  ) {
    this.signupAgentForm = this.initSignupForm();
  }

  ngOnInit() {}

  onSignupAgent({ passwords: { password, password_confirmation }, ...next }) {
    const signupObservable = this._authService.signupObservable(
      { ...next, password, password_confirmation },
      'agent'
    );

    signupObservable.subscribe(
      (response) => {
        this._router.navigate(['agent/check-email']);
      },
      (errorResponse) => {
        const errors: Array<string> = this._appService.getError(errorResponse);
        this._appService.setFormGroupErrors(this.signupAgentForm, errors);
      }
    );
  }

  initSignupForm(): FormGroup {
    return this._fb.group({
      first_name: [null, Validators.required],
      last_name: [null, Validators.required],
      email: [
        null,
        Validators.compose([Validators.required, Validators.email]),
      ],
      passwords: this._fb.group(
        {
          password: [null, Validators.required],
          password_confirmation: [null, Validators.required],
        },
        {
          validator: Validators.compose([this.passwordConfirming]),
        }
      ),
      visa: [null, Validators.required],
      marn: [null, Validators.required],
      is_agree_terms_and_policy: [false, Validators.required],
    });
  }

  passwordConfirming(c: AbstractControl): { invalid: boolean } {
    if (c.get('password').value !== c.get('password_confirmation').value) {
      c.get('password_confirmation').markAsTouched({ onlySelf: true });
      c.get('password').markAsTouched({ onlySelf: true });
      return { invalid: true };
    }
  }
}
