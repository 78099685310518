import { Component, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { QuestionService } from 'src/app/services/question.service';
import { Store, select } from '@ngrx/store';
import { ChatState } from 'src/app/store/reducers/chat.reducer';
import {
  IQuestion,
  QuestionViewOption,
} from 'src/app/models/question/question.model';
import { Subscription } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { SetChatState } from 'src/app/store/actions/chat.action';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-add-extra-credit',
  templateUrl: './add-extra-credit.component.html',
  styleUrls: ['./add-extra-credit.component.css'],
})
export class AddExtraCreditComponent {
  public extraCreditForm: FormGroup;
  public question: IQuestion;
  public chatObservable: Subscription;
  @Output() added: EventEmitter<void> = new EventEmitter();
  @Output('failed') failed: EventEmitter<object> = new EventEmitter<object>();

  constructor(
    private _fb: FormBuilder,
    private _questionService: QuestionService,
    private _store: Store<ChatState>,
    private _authService: AuthService
  ) {
    this.extraCreditForm = this.initExtraCreditForm(this._fb);
    this.chatObservable = _store
      .pipe(select('chat'))
      .subscribe((data: ChatState) => {
        this.question = data.question;
      });
  }

  initExtraCreditForm(fb: FormBuilder): FormGroup {
    return fb.group({
      amount: [0, [Validators.required, Validators.min(1)]],
    });
  }

  extraCreditSubmit(value) {
    const params = new HttpParams({ fromObject: value });
    this._questionService.addExtraCredit(this.question.id, params).subscribe(
      (data: { question: IQuestion; meta: QuestionViewOption }) => {
        this._store.dispatch(
          new SetChatState({
            question: data.question,
            viewOption: data.meta,
          })
        );
        this._authService.currentUserObservable();
        this.added.emit();
      },
      (err) => {
        const errors = err && err.error.errors;
        if (errors) {
          this.failed.next({
            errors,
            amount: this.extraCreditForm.get('amount').value,
          });
        }
      }
    );
  }
}
