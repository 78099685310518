import { Component, Input } from '@angular/core';
import { HttpModule } from "@angular/http";
import { isArray } from 'util';

@Component({
  selector: 'app-global-error',
  templateUrl: './global-error.component.html',
  styleUrls: ['./global-error.component.css']
})
export class GlobalErrorComponent {
  @Input() errors;
 
  constructor(

  ) {
    if(isArray(this.errors)){
      this.errors = this.errors.join('');
    }
   }

}
