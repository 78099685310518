import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-profile-image',
  templateUrl: './profile-image.component.html',
  styleUrls: ['./profile-image.component.css'],
})
export class ProfileImageComponent {
  @Input('url') url: string;
  @Input('class') class: string = 'user-info__avatar';
  @Input('isUpload') isUpload: boolean = false;
  constructor() {}

  getURL() {
    return this.url;
  }
}
