import { Agent } from "./agent.model";
import { Client } from "./client.model";
import { IReviewMeta } from "../review/review.model";

type SpecialData = Agent & Client;

export interface IUser {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  avatar: string;
  expiresDate: string;
  balance: number;
  created: string;
  role: string;
  fullName: string;
  countryResidenceID: number;
  specialUserData?: SpecialData;
  lastOnline: string;
}

export abstract class User implements IUser {
  constructor(
    public id: number,
    public firstName: string,
    public lastName: string,
    public email: string,
    public avatar: string,
    public expiresDate: string,
    public balance: number,
    public created: string,
    public role: string,
    public fullName: string,
    public countryResidenceID: number,
    public lastOnline: string,
    public specialUserData?: SpecialData,
    public reviewMeta?: IReviewMeta,
  ) {}
}

export class CurrentUser extends User {
  constructor({
    id = 0,
    firstName = "none",
    lastName = "none",
    email = "",
    avatar = "",
    balance = 0.0,
    expiresDate = null,
    created = null,
    role = null,
    fullName = "",
    countryResidenceID = null,
    lastOnline = null,
    specialUserData = null,
    reviewMeta = null,
  }) {
    super(
      id,
      firstName,
      lastName,
      email,
      avatar,
      expiresDate,
      balance,
      created,
      role,
      fullName,
      countryResidenceID,
      lastOnline,
      specialUserData,
      reviewMeta,
    );
  }
}
