import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-client-forget-pass',
  templateUrl: './forget-pass.component.html',
  styleUrls: ['./forget-pass.component.css'],
})
export class ClientForgetPassComponent implements OnInit {
  public form: FormGroup;
  constructor(
    private _fb: FormBuilder,
    private _authService: AuthService,
    private _router: Router,
    private _toastrService: ToastrService
  ) {}

  ngOnInit() {
    this.initForm();
  }

  initForm() {
    this.form = this._fb.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }

  send(value) {
    this._authService.forgotPass(value).subscribe((data) => {
      this._toastrService.success('Please, check your email.');
      this._router.navigate(['/client/login']);
    });
  }
}
