 import { ACTION_LOGIN, ACTION_LOGOUT } from "../actions/appActions";
 
 export interface appReducerState {
    login:Boolean,
    token:String
 }

 const initState:appReducerState = {
    login:false,
    token:null
 };
 
 export function reducer (state = initState, action){
    switch (action.type) {
		case ACTION_LOGIN:
			return {
                ...state,
                login:true,
                token:action.token
            };
		case ACTION_LOGOUT:
			return {
                ...state,                
                login:false,
                token:null
            };
		default: return {
            ...state,            
            login:false,
            token:null
        };
	}
 }