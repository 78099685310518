import { Component, OnInit, ViewChild, ElementRef, Input, OnDestroy, AfterViewInit} from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ReviewService } from "../../../../services/review.service"
import { IReview, IRejectMessage } from "../../../../models/review/review.model";
import { IQuestion, QuestionViewOption } from "../../../../models/question/question.model";
import { Store, select } from '@ngrx/store';
import { ChatState } from 'src/app/store/reducers/chat.reducer';
import { SetChatState, UnsetChatState } from "../../../../store/actions/chat.action";
import { Subscription } from 'rxjs';
import { QuestionService } from 'src/app/services/question.service';
import { ClientService } from 'src/app/services/client.service';
import { RejectMessageService } from 'src/app/services/reject-message.service';
import { ReviewViewOption } from "../../../../models/review/review.model";

// interface ReviewViewOption{
//   showAccept:boolean,
//   showReject:boolean,
//   accepted:boolean,
//   rejected:boolean
// }

@Component({
  selector: 'app-review',
  templateUrl: './review.component.html',
  styleUrls: ['./review.component.css']
})
export class ClientReviewComponent implements OnInit, OnDestroy {
  public question:IQuestion;
  public quectionViewOption: QuestionViewOption;
  public chatObservable:Subscription;
  @Input('chatID') chatID:number;
  @ViewChild('mainSection') mainSection:ElementRef;

  public review:IReview;
  public reject:IRejectMessage;

  public acceptForm: FormGroup;


  public viewOption:ReviewViewOption = {
    showAccept:false,
    showReject:false,
    accepted:false,
    rejected:false
  };

  constructor(
    private _fb: FormBuilder,
    private _reviewService:ReviewService,
    private _store:Store<ChatState>,
    private _clientService:ClientService,
  ) {
    this.chatObservable =  _store.pipe(select('chat')).subscribe((state:ChatState)=>{
      this.question = state.question;
      this.quectionViewOption = state.viewOption;
      this.initComponent();
    });
  }

  ngOnInit() {
    this.initAcceptForm();
  }

  addReview(values){
    this._reviewService.store(values).subscribe((review:IReview)=>{
      this.review = review;
      this.viewComponentsInterrelationship(['accepted'], true);
    });
  }

  accept():void{
    this._clientService.acceptAnswer(this.question.id).subscribe(
      (data:{question:IQuestion, meta:QuestionViewOption})=>{
        this._store.dispatch(new SetChatState({
          question:data.question,
          viewOption:data.meta
        }));
      }
    );
  }

  openAcceptForm():void{
    this.initAcceptForm();
    this.viewComponentsInterrelationship(['showAccept'], true);
  }

  openRejectForm():void{
    this.viewComponentsInterrelationship(['showReject'], true);
  }

  renderMain(classes:string):void{
    this.mainSection.nativeElement.className = classes;
  }

  isShowAcceptForm():boolean{
    return this.viewOption.showAccept;
  }

  isShowRejectForm():boolean{
    return this.viewOption.showReject;
  }

  isShowRejectedArea():boolean{
    return this.viewOption.rejected;
  }

  isShowAcceptedArea():boolean{
    return this.viewOption.accepted;
  }

  isShowMainSection():boolean{
    return !(this.viewOption.rejected || this.viewOption.accepted );
  }

  isShowAcceptRejectSection():boolean{
    return !([
                QuestionService.STATUS_REVIEW_COMPLETED,
                QuestionService.STATUS_COMPLETED,
                QuestionService.STATUS_ANSWER_REJECTED
            ].includes(this.quectionViewOption.status));
  }


  private initComponent():void{
    switch(this.quectionViewOption.status){
      case QuestionService.STATUS_REVIEW_COMPLETED:
        this.viewComponentsInterrelationship(['accepted'], true);
        break;
      case QuestionService.STATUS_ANSWER_REJECTED:

        this.viewComponentsInterrelationship(['rejected'], true);
        break;
      case QuestionService.STATUS_COMPLETED:
        this.openAcceptForm();
        break;
    }
    (!!this.question) && ((this.review = this.question.review) || (this.reject = this.question.rejectMessage));
  }

  initAcceptForm():void{
    this.acceptForm = this._fb.group({
      message:['', Validators.required],
      question_id:[this.question.id, Validators.required],
      rating:[0, Validators.required]
    });
  }


  private viewComponentsInterrelationship(keys:Array<string>, value:boolean):void{
    for(let i in this.viewOption){
      this.viewOption[i] = !!~keys.indexOf(i);
    }
  }

  ngOnDestroy(){
    this.chatObservable.unsubscribe();
  }



}
