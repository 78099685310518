import { Injectable } from '@angular/core';
import { API_ENDPOINT } from '../app.settings';
import { PassportService } from './passport.service';
import { map, catchError } from 'rxjs/operators';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { IQuestion } from '../models//question/question.model';

@Injectable({
  providedIn: 'root',
})
export class AgentService {
  constructor(
    private _passportService: PassportService,
    private _httpClient: HttpClient
  ) {}

  attachToQuestion(data, params?): Observable<IQuestion> {
    return this._httpClient
      .post(`${API_ENDPOINT}/question-agent`, data, {
        ...this._passportService.httpOptions,
        params: params,
      })
      .pipe(
        map((response) => {
          return response['data'];
        })
      );
  }

  cancelAnswering(questionID: number): Observable<any> {
    return this._httpClient.get(
      `${API_ENDPOINT}/agent/questions/${questionID}/cancel-answering`,
      {
        ...this._passportService.httpOptions,
      }
    );
  }

  requestExtraCredit(questionID: number, params: HttpParams) {
    return this._httpClient
      .post(
        `${API_ENDPOINT}/questions/${questionID}/request-extra-credits`,
        params,
        this._passportService.httpOptions
      )
      .pipe(
        map((response) => {
          return {
            question: response['data'],
            meta: response['meta'],
          };
        })
      );
  }

  questions(params?) {
    return this._httpClient.get(`${API_ENDPOINT}/agent/questions`, {
      ...this._passportService.httpOptions,
      params: params,
    });
  }

  getByCode(code: string) {
    return this._httpClient
      .get(`${API_ENDPOINT}/agent/find/${code}`, {
        ...this._passportService.httpOptions,
      })
      .pipe(
        map((response) => {
          return response['data'];
        })
      );
  }

  withdrawalMoneyToPaypal(params: HttpParams) {
    return this._httpClient.post(
      API_ENDPOINT + '/users/current/paypal/payout',
      params,
      this._passportService.httpOptions
    );
  }
}
