import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject, BehaviorSubject, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { map, catchError } from 'rxjs/operators';
import { API_ENDPOINT } from '../app.settings';
import { PassportService } from './passport.service';

import { isEmpty } from '../helpers/object';
import { CurrentUser, User } from '../models/user/user.model';
import { Store } from '@ngrx/store';
import { UserState } from '../store/reducers/user';
import {
  SetUserState,
  UnsetUserState,
} from '../store/actions/activeUserAction';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public static AGENT = 'agent';
  public static CLIENT = 'client';
  // private _currentUserSubject: BehaviorSubject<CurrentUser>;
  private _isLoggedSubject: Subject<boolean> = new Subject();
  // private _isSubject: Subject<string> = new Subject();

  public isLogin: Observable<boolean> = this._isLoggedSubject.asObservable();
  // public is: Observable<string> = this._isSubject.asObservable();

  // public currentUser: Observable<CurrentUser>

  constructor(
    private _httpClient: HttpClient,
    private _passportService: PassportService,
    private _router: Router,
    private _store: Store<UserState>
  ) {
    // const lUser = JSON.parse(localStorage.getItem('currentUser')) || Object.create(null);
    // this._currentUserSubject = new BehaviorSubject<CurrentUser>(new CurrentUser(lUser));
    // this.currentUser = this._currentUserSubject.asObservable();
    // this.currentUserObservable();
  }

  loginObservable({ email, password }, type: string): Observable<any> {
    return this._httpClient
      .post(
        API_ENDPOINT + '/' + type + '/login',
        {
          email,
          password,
        },
        this._passportService.httpOptions
      )
      .pipe(
        map((response) => {
          const token = response['access_token'] || '';
          this._passportService.frontToken = token;
          this.currentUserObservable();
          return response;
        })
      );
  }

  signupObservable(signupData: any, type: string): Observable<any> {
    return this._httpClient.post(
      API_ENDPOINT + `/${type}/signup`,
      signupData,
      this._passportService.httpOptions
    );
  }

  // public get currentUserValue(): CurrentUser {
  //   return this._currentUserSubject.value;
  // }

  currentUserObservable(): void {
    this._httpClient
      .get(API_ENDPOINT + '/users/current', this._passportService.httpOptions)
      .subscribe(
        (user: object) => {
          const cUser = new CurrentUser(user);
          localStorage.setItem('currentUser', JSON.stringify(cUser));
          this._store.dispatch(new SetUserState({ activeUser: cUser }));
          // this._currentUserSubject.next(cUser);
          this._isLoggedSubject.next(true);
          // this._isSubject.next(user['role']);
        },
        (error) => {
          localStorage.removeItem('currentUser');
          this._isLoggedSubject.next(false);
          // this._isSubject.next('unknown');
        }
      );
  }

  logout(role: string = 'client'): void {
    localStorage.removeItem('front_token');
    localStorage.removeItem('currentUser');
    this._store.dispatch(new UnsetUserState());
    this._router.navigate([`${role}/login`]);
  }

  is(role: string): Observable<Object> {
    return this._httpClient.get(
      `${API_ENDPOINT}/${role}/is`,
      this._passportService.httpOptions
    );
  }

  setPassword(data): Observable<any> {
    return this._httpClient.post(`${API_ENDPOINT}/password/reset`, data);
  }

  changePassword(passwords): Observable<any> {
    return this._httpClient.post(
      `${API_ENDPOINT}/users/current/change-password`,
      passwords,
      this._passportService.httpOptions
    );
  }

  forgotPass(data): Observable<any> {
    return this._httpClient.post(
      `${API_ENDPOINT}/auth/forgot-pass`,
      data,
      this._passportService.httpOptions
    );
  }
}
