import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';
import { of } from "rxjs";
import { API_ENDPOINT } from "../app.settings";
import { HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { PassportService } from "./passport.service";


@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private _httpClient:HttpClient,
    private _router:Router,
    private _passportService:PassportService
  ) {
   }

  // defaultCard():Observable<object>{
  //   return this._httpClient.get(API_ENDPOINT+'/users/current/stripe/carts/default', this._passportService.httpOptions);
  // }

  confirmUser(token:string):Observable<boolean>{
    return this._httpClient.post(API_ENDPOINT+'/auth/confirm/email',{ token },this._passportService.httpOptions).pipe(
      map((response:{isConfirmed:boolean, role:string})=>{
        this._router.navigate([`/${response.role}/login`]);
        return true;
      }),
      catchError(error =>{
        this._router.navigate(['/']);
        return of(false);
      })
    );
  }

  update(data){
    return this._httpClient.post(API_ENDPOINT+'/profile', data ,this._passportService.httpOptions);
  }
}
