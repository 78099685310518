import { Component, OnDestroy } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ChatService } from "../../../services/chat.service";
import { QuestionService } from "../../../services/question.service";
import {
  IQuestion,
  QuestionViewOption
} from "../../../models/question/question.model";
import { IChat } from "../../../models/chat/chat.model";
import { Store, select } from "@ngrx/store";
import { ChatState } from "src/app/store/reducers/chat.reducer";
import {
  SetChatState,
  UnsetChatState
} from "../../../store/actions/chat.action";
import { SetUiState, UnsetUiState } from "src/app/store/actions/ui.action";
import { UiState } from "src/app/store/reducers/ui.reducer";
import { AgentService } from "src/app/services/agent.service";
import { map } from "rxjs/operators";

@Component({
  selector: "app-agent-chat",
  templateUrl: "./chat.component.html",
  styleUrls: ["./chat.component.css"]
})
export class AgentChatComponent implements OnDestroy {
  private _chatId: number;
  private _questionId: number;
  public question: IQuestion;
  public chat: IChat;
  public viewOption: QuestionViewOption;
  public chatObservable;

  constructor(
    private _agentService: AgentService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _chatService: ChatService,
    private _questionService: QuestionService,
    private _store: Store<ChatState>,
    private _uiStore: Store<UiState>
  ) {
    this.chatObservable = _store
      .pipe(select("chat"))
      .subscribe((data: ChatState) => {
        this.question = data.question;
        this.viewOption = data.viewOption;
        this.chat = data.chat;
      });

    _uiStore.dispatch(new SetUiState({ subTitle: "Question" }));

    this._route.params.subscribe(params => {
      this._questionId = params["questionID"];
      this._chatId = params["chatID"] as number;
      this._questionService
        .getQuestion(this._questionId)
        .subscribe(
          (data: { question: IQuestion; meta: QuestionViewOption }) => {
            this._store.dispatch(
              new SetChatState({
                question: data.question,
                viewOption: data.meta
              })
            );
          }
        );
      this._chatService
        .getChat(this._chatId)
        .subscribe((chat: IChat) => {
          this._store.dispatch(
            new SetChatState({
              chat: chat
            })
          );
        });
    });
  }

  cancelAnswering() {
    this._agentService.cancelAnswering(this._questionId).subscribe(data => {
      this._router.navigate(["/agent/dashboard/question-pool"]);
    });
  }

  isShowCancelAnswering() {
    return this.viewOption
      ? this.viewOption.status == QuestionService.STATUS_AGENT_ASSIGNED
      : false;
  }

  ngOnDestroy() {
    this._store.dispatch(new UnsetChatState());
    this._uiStore.dispatch(new UnsetUiState());
    this.chatObservable.unsubscribe();
  }
}
