import { Component, OnInit } from '@angular/core';
import { Store } from "@ngrx/store";
import { ACTION_LOGIN, ACTION_LOGOUT } from "../store/actions/appActions";
import { PassportService } from "../services/passport.service";


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  constructor(
    private _store: Store<any>,
    private _passportService:PassportService
  ) {

  }

  ngOnInit() {
  }

  login():void{
    this._store.dispatch({type:ACTION_LOGIN});
  }

  logout():void{
    this._store.dispatch({type:ACTION_LOGOUT});
  }

  getCurrentYear(){
    return (new Date()).getFullYear();
  }



}
