import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  constructor() { }

  setItem(key:string, value:any):void{
    value = (value instanceof Object) ? JSON.stringify(value) : value;
    localStorage.setItem(key, value);
  }

  getItem(key:string):any{
    return localStorage.getItem(key);
  }

  remove(key:string):void{
    localStorage.removeItem(key);
  }
}
