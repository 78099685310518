import { Component, forwardRef, Input, Output, EventEmitter, OnInit, ViewEncapsulation, OnChanges } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";


@Component({
  selector: 'app-rating',
  templateUrl: './rating.component.html',
  styleUrls: ['./rating.component.css'],
  encapsulation: ViewEncapsulation.ShadowDom,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RatingComponent),
      multi: true,
    }
  ],
})

export class RatingComponent implements OnInit, ControlValueAccessor, OnChanges {
  @Input() rating: number = 0;
  @Input() disable:boolean = false;
  @Input() itemId: number;
  @Output() ratingClick: EventEmitter<any> = new EventEmitter<any>();

  private _selectValue:number = 0;
  private _onTouchedCallback: () => {};
  private _onChangeCallback: (_:any) => {};


  inputName: string;

  ngOnInit() {
    this.inputName = this.itemId + '_rating';
  }

  ngOnChanges(){
    this.rating = Math.ceil(this.rating);
  }


  onClick(rating: number): void {
    if(this.disable) return;
    this.selectValue = rating;
    this.rating = rating;
    this.ratingClick.emit({
      itemId: this.itemId,
      rating: rating
    });
  }

  get selectValue(): any {
    return this._selectValue;
  }
  set selectValue(value: any) {
    this.writeValue(value);
    this._onChangeCallback(value);
    this._onTouchedCallback();
  }


  writeValue(value: any) {
    this._selectValue = value;
  }

  registerOnChange(fn: any) {
    this._onChangeCallback = fn || (() => {});
  }

  registerOnTouched(fn: any) {
    this._onTouchedCallback = fn || (() => {});
  }

}
