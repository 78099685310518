import { Action } from '@ngrx/store';
import * as activeUserAction from '../actions/activeUserAction';
import { IUser } from "../../models/user/user.model";

export interface UserState {
  activeUser:IUser
}

export const initialState: UserState = {
  activeUser: <IUser>JSON.parse(localStorage.getItem('currentUser')) || null
}

export function reducer(state = initialState, action:activeUserAction.Action) {
    switch (action.type) {
      case activeUserAction.SET: {
        return {
          ...state,
          ...action.payload
        };
      }
      case activeUserAction.UNSET:
        return initialState;
      default:
        return state;
    }
}
