import {
  Component,
  OnInit,
  AfterViewInit,
  ViewChildren,
  ViewContainerRef,
  Input,
  QueryList,
  Type,
  ComponentRef,
  ReflectiveInjector,
  ChangeDetectorRef,
  ComponentFactoryResolver,
   } from '@angular/core';
import { HttpParams} from '@angular/common/http';
import { Observable, Observer, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-async-paginate',
  templateUrl: './async-paginate.component.html',
  styleUrls: ['./async-paginate.component.css'],

})
export class AsyncPaginateComponent implements OnInit{
  @ViewChildren('container', {read: ViewContainerRef}) private _container:QueryList<ViewContainerRef>;
  @Input() private _type:Component;
  private _cmpRef:ComponentRef<any>;
  @Input('data') dataObserver;
  @Input('title')title:string = 'History';
  @Input('refComponent') refComponent:Type<Component>;
  @Input() updated:Observable<void> = new Observable<void>();
  private eventsSubscription:Subscription;
  public items$;

  public p: number = 1;
  public total: number;
  public loading: boolean;
  public perPage:number = 1;


  constructor(
    private _componentFactoryResolver:ComponentFactoryResolver,
    private _cd: ChangeDetectorRef,
    ) {
  }

  ngOnInit(){
    this.eventsSubscription = this.updated.subscribe(() => {
      this.runObserver();
    })
    this.runObserver();
  }

  runObserver(){
    this.dataObserver(new HttpParams().set('page', this.p.toString())).subscribe(
      (response)=>{
        this.items$ = response.data;
        this.total = response.total;
        this.perPage = +response.per_page;
        this.loading = false;
      });
  }

  ngAfterViewInit(){
    this._container.changes.subscribe(() => {
      this.refresh();
    });
  }

  public refresh() {
    let component = this.refComponent;

    if(typeof(this._container) !== "undefined"){
      for (let i = 0; i < this._container.toArray().length; i++) {
        let target = this._container.toArray()[i];
        let widgetComponent = this._componentFactoryResolver.resolveComponentFactory(component);
        const injector = ReflectiveInjector.resolveAndCreate([
          {
            provide: 'paginateItemData',
            useValue:  this.items$[i]
          }
        ]);
        target.clear();
        const _cmpRef = target.createComponent(widgetComponent, 0, injector);
        this._cd.detectChanges();
      }
    }
  }

  getPage(page){
    this.p = page;
    this.dataObserver(new HttpParams().set('page', this.p.toString())).subscribe(
      (response:any)=>{
        this.total = response.total;
        this.items$ = response.data;
        this.loading = false;
      }
    )
  }

  showPaginate():boolean{
    return !!this.items$ && this.items$.length;
  }

}
