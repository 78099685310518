import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { QuestionStoryItemComponent } from "../questions/questions-story/question-story-item/question-story-item.component";
import { QuestionService } from "../../../services/question.service";
import { Subject } from 'rxjs';


@Component({
  selector: 'app-inbox-questions',
  templateUrl: './inbox-questions.component.html',
  styleUrls: ['./inbox-questions.component.css'],
  entryComponents: [
    QuestionStoryItemComponent,
  ]
})
export class ClientInboxQuestionsComponent {
  public questionsSubject: Subject<void> = new Subject<void>();
  public filter:Object = new Object;
  constructor(
    public questionService:QuestionService
  ) { }

  changedFilter(event){
    this.filter = event;
    this.questionsSubject.next();
  }

  paginateComponentRef(){
    return QuestionStoryItemComponent;
  }

  questions(params:HttpParams){
    params = this.toQuery(params, this.filter);
    return this.questionService.clientQuestionsStory(params);
  }

  toQuery(params:HttpParams, obj):HttpParams{
    Object.keys(obj).map(function(key) {
      const value = obj[key];
      if(value){
        params = params.append(key, value);
      }
    });
    return params;
  }

}
