import { Component, OnInit, Input, ViewChild, ElementRef } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ChatService } from "../../../../services/chat.service";
import { MessageService } from "../../../../services/message.service";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { IChat } from "../../../../models/chat/chat.model";
import {
  IQuestion,
  QuestionViewOption
} from "src/app/models/question/question.model";
import { QuestionService } from "src/app/services/question.service";
import { IMessage } from "src/app/models/chat/message.model";
import { ISystemMessage } from "src/app/models/chat/system-message.model";
import { Store } from "@ngrx/store";
import { ChatState } from "src/app/store/reducers/chat.reducer";
import { SetChatState } from "src/app/store/actions/chat.action";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-agent-chat-area",
  templateUrl: "./chat-area.component.html",
  styleUrls: ["./chat-area.component.css"]
})
export class AgentChatAreaComponent {
  private _chatId: number;
  private _modal;
  public dragFiles: Array<File> = [];
  @Input("chat") chat: IChat;
  @Input("question") question: IQuestion;
  @ViewChild("chatContent") chatContent: ElementRef;
  @Input("viewOption") viewOption: QuestionViewOption;

  public messageForm: FormGroup = new FormGroup({
    message: new FormControl(null, Validators.required),
    "files[]": new FormControl(null)
  });

  constructor(
    private _route: ActivatedRoute,
    private _messageService: MessageService,
    private _chatService: ChatService,
    private _store: Store<ChatState>,
    private _modalService: NgbModal
  ) {
    this._route.params.subscribe(params => {
      this._chatId = params["chatID"] as number;
    });
  }

  ngAfterViewChecked() {
    this.chatContent.nativeElement.scrollTop = this.chatContent.nativeElement.scrollHeight;
  }

  sendMessage(message) {
    message["files[]"] = this.dragFiles;
    this._messageService
      .sendMessage(this._chatId, message)
      .subscribe(message => {
        this.messageForm.reset();
        this.dragFiles = [];
        this._chatService.getChat(this._chatId).subscribe((chat: IChat) => {
          this._store.dispatch(
            new SetChatState({
              chat: chat
            })
          );
        });
      });
  }

  openRequestCreditPopup(content) {
    this._modal = this._modalService.open(content, {
      ariaLabelledBy: "modal-basic-title",
      size: "lg"
    });
  }

  requestCreditHandler() {
    this._modal.close();
    this._chatService.getChat(this._chatId).subscribe((chat: IChat) => {
      this._store.dispatch(
        new SetChatState({
          chat: chat
        })
      );
    });
  }

  isMessage(message): boolean {
    return !!message["body"];
  }

  isSystemMessage(message): boolean {
    return !!message["text"];
  }

  filesHandler(event) {
    this.messageForm.patchValue({ "files[]": event.target.files });
  }

  isShowMessageArea(): boolean {
    return this.viewOption ? !!this.viewOption.canSendMessage : false;
  }

  isShowRequestExtraCreditsArea(): boolean {
    return this.viewOption ? !!this.viewOption.canRequestExtraCredits : false;
  }

  isShowReviewArea() {
    return this.viewOption
      ? this.viewOption.status == QuestionService.STATUS_REVIEW_COMPLETED
      : false;
  }

  isShowRejectMessageArea() {
    return this.viewOption
      ? this.viewOption.status == QuestionService.STATUS_ANSWER_REJECTED
      : false;
  }
}
