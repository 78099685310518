import { Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { UiState } from 'src/app/store/reducers/ui.reducer';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-agent-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class AgentDashboardComponent implements OnDestroy {
  public currentPageTitle:string;
  public titleObserver:Subscription;
  constructor(
    private _store:Store<UiState>,
    private _authService:AuthService
  ) {
    _authService.currentUserObservable(); 
    this.titleObserver =  _store.pipe(select('ui')).subscribe((state:UiState)=>{
      this.currentPageTitle = state.subTitle;
    });
  }

  ngOnDestroy(){ //need array
    this.titleObserver.unsubscribe();
  }

}
